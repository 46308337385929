/**
 * Parameters for identifying a user
 *
 * @typedef {Object} HeapUserParams
 *  @property {string} userId
 *  @property {string} firstName
 *  @property {string} lastName
 *  @property {string} email
 *  @property {string} userName
 */

/**
 *
 * Identifies the current user with Heap.io
 *
 * @param {object} window The global window object
 * @param {object} logger A logger object
 * @param {HeapUserParams} userParams The parameters that identify the user
 * @returns true if Heap was called successfully, false otherwise
 */
export const identifyUser = (window, logger, userParams) => {
    if (window.heap) {
        try {
            window.heap.identify(userParams.userId);
            window.heap.addUserProperties({
                FirstName: userParams.firstName,
                LastName: userParams.lastName,
                Email: userParams.email,
                UserName: userParams.userName
            });

            return true;
        } catch (err) {
            logger.error(`Failure when calling Heap.io: ${err.message}`);
            return false;
        }
    }

    return false;
};
