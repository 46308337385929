import {default as defaultTransitions} from '@material-ui/core/styles/transitions';

import type {Transitions} from '@material-ui/core/styles/transitions';

export const transitionsNormal: Partial<Transitions> = {
    duration: {
        shortest: defaultTransitions.duration.shortest, // default
        shorter: defaultTransitions.duration.shorter, // default
        short: defaultTransitions.duration.short, // default
        standard: defaultTransitions.duration.standard, // default
        complex: defaultTransitions.duration.complex, // default
        enteringScreen: 150,
        leavingScreen: 120
    }
};

export const transitionsReduced: Partial<Transitions> = {
    duration: {
        shortest: transitionsNormal.duration.shortest / 2,
        shorter: transitionsNormal.duration.shorter / 2,
        short: transitionsNormal.duration.short / 2,
        standard: transitionsNormal.duration.standard / 2,
        complex: transitionsNormal.duration.complex / 2,
        enteringScreen: transitionsNormal.duration.enteringScreen / 2,
        leavingScreen: transitionsNormal.duration.leavingScreen / 2
    }
};

export const transitionsNone: Partial<Transitions> = {
    duration: {
        shortest: 0,
        shorter: 0,
        short: 0,
        standard: 0,
        complex: 0,
        enteringScreen: 0,
        leavingScreen: 0
    }
};
