// Note: any camelCase names here are all-lowercase in HTML. It's just React JSX
// that camelcases them, so for example `autocomplete` in HTML becomes
// `autoComplete` in React.
export default function inputPropTypes(PropTypes) {
    return {
        autoComplete: PropTypes.string,
        autoFocus: PropTypes.bool,
        maxLength: PropTypes.number,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        required: PropTypes.bool,
        size: PropTypes.number,
        value: PropTypes.any
    };
}
