import axios from 'axios';
import Immutable from 'immutable';
import QS from 'qs';

import isDate from 'lodash/isDate';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {store} from 'redux-store';

// constants
import * as EnvironmentConstants from 'constants/environment-constants';
import ErrorActionTypes from 'constants/error-action-types';
import {UNAUTHORIZED_REQUEST} from 'constants/session-constants';

// utils
import MeteorCookies from 'utils/meteor-cookies';
import UIErrorCodeUtils from 'utils/ui-error-code-utils';

import type {AxiosError, AxiosRequestConfig} from 'axios';
import type {Map} from 'immutable';

type ShadowedConfigProps =
    | 'method'
    | 'data'
    | 'headers'
    | 'params'
    | 'responseType'
    | 'transformResponse'
    | 'onUploadProgress'
    | 'onDownloadProgress';
type ShadowedConfigInterface = Pick<AxiosRequestConfig, ShadowedConfigProps>;
export type RequestOptions = ShadowedConfigInterface & {
    endpoint: string;
    contentType?: string;
};
type RequestHeaders = {
    'client-id': string;
    token: string;
    'user-id': string;
};

// Webpack sets these variables.
const API_ROOT = __API_HOST__;
const EXAM_API_URI = __UI_EXAM_WEBSOCKET_URI__;
const VERA_SOCKET_API_URI = __UI_VERA_SOCKET_API_URI__;
const simcaptureUiVersion = __VERSION__;
const uiService = __UI_SERVICE__;

export function createAxiosRetryInterceptor(depth: number, delayTime: number, retryLimit: number) {
    return [
        (resp) => resp,
        async (error) => {
            const {config, response} = error;
            const status = response ? response.status : null;
            if (status === 502 || status === 504) {
                if (depth >= retryLimit) {
                    return Promise.reject(error);
                } else {
                    const delay = delayTime * Math.pow(2, depth);
                    const randomSum = delay * 0.2 * Math.random();
                    const delayWithJitter = delay + randomSum;
                    await new Promise((resolve) => setTimeout(resolve, delayWithJitter));
                    const a = axios.create();
                    a.interceptors.response.use(...createAxiosRetryInterceptor(depth + 1, delayTime, retryLimit));
                    return a(config);
                }
            } else {
                return Promise.reject(error);
            }
        }
    ];
}

// the following check for empty, not nil, to handle '' responses as errors too

export function getApiRoot() {
    if (isEmpty(API_ROOT)) {
        throw new Error('API_ROOT never set.');
    }
    return API_ROOT;
}

export function getExamApiUri() {
    if (isEmpty(EXAM_API_URI)) {
        throw new Error('EXAM_API_URI never set.');
    }
    return EXAM_API_URI;
}

export function getVeraSocketApiUri() {
    if (isEmpty(VERA_SOCKET_API_URI)) {
        throw new Error('VERA_SOCKET_API_URI never set.');
    }
    return VERA_SOCKET_API_URI;
}

/**
 * Conditionally add Transitional Authentication Header if applicable.
 *
 * @returns request with transitional auth header if useTransitionalAuth is not nil
 */
export function addTransitionalAuthHeader(requestConfig: AxiosRequestConfig, useTransitionalAuth?: boolean) {
    if (!isNil(useTransitionalAuth)) {
        requestConfig.headers = {
            'use-transitional-auth': useTransitionalAuth
        };
    }

    return requestConfig;
}

/**
 * Configures axios call for an authenticated request.
 */
export function createAuthenticatedRequest(clientId: string, userId: string, requestOptions: RequestOptions) {
    const token = MeteorCookies.getInstance().get('token');

    const headers = {
        'client-id': clientId,
        token,
        'user-id': userId
    };

    return createRequest(headers, requestOptions);
}

/**
 * Create a request
 *
 * @todo SCLD-9459 Update Axios and use AxiosRequestConfig to define request
 */
export function createRequest(headers: RequestHeaders, requestOptions: RequestOptions): AxiosRequestConfig {
    const request: AxiosRequestConfig = {
        url: fullUrl(requestOptions.endpoint),
        paramsSerializer,
        headers: {
            // matches label for datadog logging, see main.js
            'service-consumer-name': EnvironmentConstants.SERVICE_CONSUMERS.SIMCAPTURE_CLOUD,
            'service-consumer-version': simcaptureUiVersion,
            'service-consumer-service': uiService
        }
    };

    if (!isEmpty(requestOptions.headers)) {
        Object.entries(requestOptions.headers).forEach(([key, value]) => {
            request.headers[key] = value;
        });
    }

    Object.entries(headers).forEach(([key, value]) => {
        request.headers[key] = value;
    });

    if (requestOptions.method) {
        request.method = requestOptions.method;
    }

    // data should be plain javascript
    if (Immutable.isImmutable(requestOptions.data)) {
        request.data = requestOptions.data.toJS();
    } else {
        request.data = requestOptions.data;
    }

    if (requestOptions.contentType) {
        request.headers['Content-Type'] = requestOptions.contentType;
    }

    if (requestOptions.responseType) {
        request.responseType = requestOptions.responseType;
    }

    if (requestOptions.params) {
        request.params = requestOptions.params;
    }

    if (requestOptions.onUploadProgress) {
        request.onUploadProgress = requestOptions.onUploadProgress;
    }

    if (requestOptions.onDownloadProgress) {
        request.onDownloadProgress = requestOptions.onDownloadProgress;
    }

    if (requestOptions.transformResponse) {
        request.transformResponse = requestOptions.transformResponse;
    }

    return request;
}

export function executeApiRequest(request: AxiosRequestConfig) {
    return axios(request);
}

/**
 * Make a call to the SimCapture api with no error handling - authentication/authorization errors, 500s, etc will all be thrown back at the caller.
 *
 * @param client Current SimCapture client
 * @param loggedInUser Logged in user making this request
 * @param requestOptions Axios request payload
 * @returns A promise that resolves to the axios response data.
 */
export function callApiUnsafe(
    client: Map<string, string>,
    loggedInUser: Map<string, string>,
    requestOptions: RequestOptions
) {
    const clientId = client.get('clientId');
    const userId = loggedInUser.get('userId');
    return executeApiRequest(createAuthenticatedRequest(clientId, userId, requestOptions)).then((response) => {
        return Immutable.fromJS(response.data);
    });
}

/**
 * Calls the backend API to make an authenticated request as the currently
 * logged in user. Wraps `executeApiRequest` to handle errors by passing them to
 * the Meteor redux store, so they can be displayed in the usual fashion.
 *
 * Note: Unlike middleware/api.js this function does not have backwards
 * compatibility with old-style Meteor errors. Use this for API endpoints that
 * do not produce legacy errors. The expectation is that only 401s, 403s, and
 * 500s are handled here. All other error types should be expected and handled
 * within the view that calls this function.
 *
 * @deprecated SCLD-14736 use callApiMeteorAuthenticated()
 */
export function callApiMeteor(
    client: Map<string, string>,
    loggedInUser: Map<string, string>,
    requestOptions: RequestOptions
) {
    return callApiUnsafe(client, loggedInUser, requestOptions).catch(handleGenericApiError);
}

/**
 * This util augments callApiMeteor to generically handle all errors.
 *
 * There are many calls which we expect to work because of UI limitations,
 * and if they fail for any reason, we just want to force the user to refresh
 * and/or contact us. Use this function for those calls.
 *
 * @deprecated use handleUnknownApiError() directly if an error is caught
 */
export function callApiMeteorHandleErrors(
    client: Map<string, string>,
    loggedInUser: Map<string, string>,
    requestOptions: RequestOptions
) {
    return callApiMeteor(client, loggedInUser, requestOptions).catch(handleUnknownApiError);
}

/**
 * Makes authenticated API requests and handles generic 401, 403, and 500 errors.
 */
// eslint-disable-next-line
export const callApiMeteorAuthenticated = async <T = any>(requestOptions: RequestOptions): Promise<T> => {
    return callApiMeteorAuthenticatedUnsafe<T>(requestOptions).catch(handleGenericApiError);
};

/**
 * Makes unauthenticated API requests with no error handling - authentication/authorization errors, 500s, etc will all be
 * thrown back at the caller.
 */
export const callApiMeteorUnauthenticatedUnsafe = async (requestOptions: RequestOptions) => {
    // @ts-expect-error createRequest always expects specific headers but we won't have those before we are authenticated
    const response = await axios(createRequest({}, requestOptions));

    return Immutable.fromJS(response.data);
};

/**
 * Makes authenticated API requests with no error handling - authentication/authorization errors, 500s, etc will all be
 * thrown back at the caller.
 */
// eslint-disable-next-line
export const callApiMeteorAuthenticatedUnsafe = async <T = any>(requestOptions: RequestOptions): Promise<T> => {
    const headers = {
        'client-id': MeteorCookies.getInstance().get('clientId'),
        token: MeteorCookies.getInstance().get('token'),
        'user-id': MeteorCookies.getInstance().get('userId')
    };
    const response = await axios(createRequest(headers, requestOptions));

    return Immutable.fromJS(response.data);
};

/**
 * Makes authenticated API requests with no error handling - authentication/authorization errors, 500s, etc will all be
 * thrown back at the caller.
 */
export const callApiMeteorAuthenticatedUnsafeJS = async <T>(requestOptions: RequestOptions) => {
    const headers = {
        'client-id': MeteorCookies.getInstance().get('clientId'),
        token: MeteorCookies.getInstance().get('token'),
        'user-id': MeteorCookies.getInstance().get('userId')
    };
    const response = await axios<T>(createRequest(headers, requestOptions));

    return response.data;
};

/**
 * Catches 401, 403, and 500 errors for purposes of displaying our generic error
 * modal. Rethrows in case the caller wants to do something else with the error
 * object.
 */
export function handleGenericApiError(error: AxiosError): never {
    const {response} = error;
    if (!response) {
        // This is an axios-level error with no response data
        throw error;
    }

    if (response.status === 401 || response.status === 403) {
        // handle unauthorized requests
        store.dispatch({
            type: UNAUTHORIZED_REQUEST,
            data: {response}
        });
    } else if (response.status >= 500) {
        // handle internal server errors
        store.dispatch({
            type: ErrorActionTypes.ACTION_ERROR,
            data: Immutable.fromJS(response.data)
        });
    }

    // rethrow the same error object we received
    throw error;
}

export function handleUnknownApiError(error: AxiosError) {
    const {response} = error;
    // This value is expected to contain a traceId
    // Internal server error modal will display a message that it is missing if there is no response
    const data = isNil(response) ? {} : response.data;

    store.dispatch({
        type: ErrorActionTypes.ACTION_ERROR,
        data: Immutable.fromJS(data)
    });
}

import type {ErrorCode} from 'constants/error-codes';

/**
 * @returns True if the errorCode is a known error.
 */
export function isKnownError(error: AxiosError<ErrorCode | Map<unknown, unknown>>, errorCode: ErrorCode) {
    const {response} = error;

    return !isNil(response) && !isNil(response.data) && UIErrorCodeUtils.errorCodesAreEqual(response.data, errorCode);
}

function paramsSerializer(params: object) {
    return QS.stringify(params, {
        arrayFormat: 'repeat',
        filter: (prefix, value) => {
            if (value && (isDate(value) || value._isAMomentObject)) {
                return value.toJSON();
            }
            return value;
        }
    });
}

export const fullUrl = (endpoint: string) => {
    return endpoint.indexOf(getApiRoot()) === -1 ? getApiRoot() + endpoint : endpoint;
};
