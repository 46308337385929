import React, {useContext} from 'react';
import isNil from 'lodash/isNil';
import {ButtonProps} from '@material-ui/core/Button';

import Button from '@material-ui/core/Button';
import {ButtonContext} from 'hooks/button-context';

import InProgressIcon from 'components/in-progress-icon';

export type SCButtonProps = Pick<ButtonProps, 'disabled' | 'fullWidth' | 'onClick' | 'size' | 'startIcon' | 'type'> & {
    critical?: boolean;
    inProgress: boolean;
    label: string;
    variant?: 'primary' | 'secondary' | 'tertiary';
};

const SCButton = ({
    critical = false,
    disabled: disabledProp,
    fullWidth = false,
    inProgress,
    label,
    onClick,
    size,
    startIcon,
    type,
    variant: variantProp = 'primary'
}: SCButtonProps) => {
    const context = useContext(ButtonContext);
    const {size: contextSize, disabled: contextDisabled} = context;
    // Explicit props takes precedence. Context will be used if no prop provided.
    // Default value will be used if neither are provided.
    let buttonSize: ButtonProps['size'];
    if (!isNil(size)) {
        buttonSize = size;
    } else if (!isNil(contextSize)) {
        buttonSize = contextSize;
    } else {
        buttonSize = 'medium';
    }
    // Disabled uses union of values, so if anything is providing a true value
    // The button will be disabled
    let disabled = disabledProp || contextDisabled;
    const color = critical ? 'secondary' : 'primary';

    let variant: ButtonProps['variant'];
    if (variantProp === 'primary') {
        variant = 'contained';
    } else if (variantProp === 'secondary') {
        variant = 'outlined';
    } else {
        variant = 'text';
    }

    let inProgressIcon = startIcon;
    if (inProgress) {
        inProgressIcon = <InProgressIcon />;
        disabled = true;
    }

    return (
        <Button
            color={color}
            disabled={disabled}
            disableElevation
            disableRipple
            fullWidth={fullWidth}
            onClick={onClick}
            // This is a coalesced value from react context and props
            size={buttonSize}
            startIcon={inProgressIcon}
            type={type}
            variant={variant}>
            {label}
        </Button>
    );
};

export default React.memo(SCButton);
