import * as constants from 'constants/intl-constants';
import {UPDATE_MESSAGES} from 'middleware/intl';

export function setMessages(moduleNames, messages) {
    return {
        type: constants.SET_MESSAGES,
        messages,
        moduleNames
    };
}

export function updateMessages(module, success, failure, smartlingEnabled = false) {
    return {
        [UPDATE_MESSAGES]: {
            types: [constants.MESSAGES_REQUEST, constants.MESSAGES_SUCCESS],
            module,
            success,
            failure,
            smartlingEnabled
        }
    };
}

export function updateFormats(formats) {
    return {
        type: constants.UPDATE_FORMATS,
        formats: formats
    };
}
