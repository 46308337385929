import PropTypes from 'prop-types';
import React, {useContext} from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import {ModalContext} from 'components/modals/modal-context';

import * as ModalConstants from '../modal-constants';
import {modalMarginSm} from '../modal-constants';

import type {PositionProperty} from 'csstype';

const useStyles = makeStyles((theme) => {
    const rootCommon = {
        ...theme.typography.componentM,
        fontWeight: theme.typography.fontWeightBold as number,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    };
    const closeButtonCommon = {
        position: 'absolute' as PositionProperty
    };

    return {
        rootSmall: {
            ...rootCommon,
            padding: ModalConstants.modalMarginSm
        },
        rootMedium: {
            ...rootCommon,
            padding: ModalConstants.modalMarginMd,
            paddingBottom: modalMarginSm
        },
        rootLarge: {
            ...rootCommon,
            padding: ModalConstants.modalMarginLg,
            paddingBottom: modalMarginSm
        },
        closeButtonSmall: {
            ...closeButtonCommon,
            right: ModalConstants.modalMarginSm
        },
        closeButtonMedium: {
            ...closeButtonCommon,
            right: ModalConstants.modalMarginMd
        },
        closeButtonLarge: {
            ...closeButtonCommon,
            right: ModalConstants.modalMarginLg
        }
    };
});

const ModalTitle = ({title}) => {
    const classes = useStyles();
    const {size, open, onClose} = useContext(ModalContext);

    let root;
    let closeButton;
    switch (size) {
        case 'small':
            root = classes.rootSmall;
            closeButton = classes.closeButtonSmall;
            break;
        case 'large':
            root = classes.rootLarge;
            closeButton = classes.closeButtonLarge;
            break;
        case 'medium':
        default:
            root = classes.rootMedium;
            closeButton = classes.closeButtonMedium;
            break;
    }

    return (
        <DialogTitle
            disableTypography
            classes={{
                root
            }}>
            {title}
            <IconButton className={closeButton} disabled={!open} aria-label='close' onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
};

ModalTitle.propTypes = {
    title: PropTypes.string.isRequired
};

export default ModalTitle;
