import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';

import {
    COURSE_OWNER,
    COURSE_CONTRIBUTOR,
    COURSE_MONITOR,
    COURSE_VIEWER,
    COURSE_PARTICIPANT,
    COURSE_PATIENT_MONITOR
} from 'constants/LU_PermissionGroups';

import globalMessages from 'intl/global-messages';

import {getOwnerFromInstance, getUserGroupUsersFromInstanceRoles, getOrgsFromInstanceRoles} from './role-utils';

export const getCourseTitleSelection = (noneSelection, course) => {
    let title = noneSelection;

    if (isNil(course) || course.isEmpty()) {
        return title;
    }

    const privateTitle = course.get('privateTitle');
    const publicTitle = course.get('publicTitle');

    if (!isNil(privateTitle)) {
        title = privateTitle;
    } else if (!isNil(publicTitle)) {
        title = publicTitle;
    }

    return title;
};

export const getCourseTitle = (course, archivedMessage = 'Archived', canViewPrivateTitle = true) => {
    let title = '';

    if (isNil(course) || course.isEmpty()) {
        return title;
    }

    const privateTitle = course.get('privateTitle');
    const publicTitle = course.get('publicTitle');

    if (!canViewPrivateTitle && !isNil(publicTitle)) {
        title = publicTitle;
    } else if (!isNil(privateTitle)) {
        title = privateTitle;
    }

    if (archivedMessage && !course.get('active')) {
        title = `${title} (${archivedMessage})`;
    }

    return title;
};

export const getCourseTitleWithStatus = (course, formatMessage) => {
    const archivedMessage = formatMessage(globalMessages.archived);
    let courseTitle = getCourseTitle(course, archivedMessage);

    if (course.get('active')) {
        const courseStatusMessageId = globalMessages[course.getIn(['courseStatus', 'ref'])];
        if (!isNil(courseStatusMessageId)) {
            courseTitle = `${courseTitle} (${formatMessage(courseStatusMessageId)})`;
        }
    }
    return courseTitle;
};

export const getCourseUrl = (course) => {
    if (isNil(course) || course.isEmpty() || isNil(course.get('courseId'))) {
        return null;
    }

    return `/courses/${course.get('courseId')}`;
};

export const courseOwner = (course) => {
    return getOwnerFromInstance(course, COURSE_OWNER);
};

export const addCourseOwnerToOptions = (options, course) => {
    const originalOwner = courseOwner(course);

    if (
        !originalOwner.isEmpty() &&
        isUndefined(options.find((user) => user.get('userId') === originalOwner.get('userId')))
    ) {
        options = options.push(originalOwner);
    }

    return options;
};

export const courseContributors = (course) => {
    return getUserGroupUsersFromInstanceRoles(course, COURSE_CONTRIBUTOR);
};

export const getCourseMonitors = (course) => {
    return getUserGroupUsersFromInstanceRoles(course, COURSE_MONITOR);
};

export const courseViewers = (course) => {
    const users = getUserGroupUsersFromInstanceRoles(course, COURSE_VIEWER);
    const organizations = getOrgsFromInstanceRoles(course, COURSE_VIEWER);

    return users.concat(organizations);
};

// deprecated, use the below alias instead
export const courseParticipants = (course) => {
    return getUserGroupUsersFromInstanceRoles(course, COURSE_PARTICIPANT);
};

/**
 * @param {Immutable.Map} course
 * @return {Immutable.List}
 */
export const getCourseParticipants = (course) => {
    return getUserGroupUsersFromInstanceRoles(course, COURSE_PARTICIPANT);
};

export const getPatientMonitorPG = (course) => {
    return course.get('roles').find((role) => {
        return role.getIn(['permissionGroup', 'ref']) === COURSE_PATIENT_MONITOR;
    });
};

export const getPatientsCanMonitor = (course) => {
    let patientsCanMonitor = false;
    if (!isNil(course) && !isNil(course.get('roles'))) {
        patientsCanMonitor = !getPatientMonitorPG(course).get('userGroups').isEmpty();
    }
    return patientsCanMonitor;
};
