import React from 'react';
import isNil from 'lodash/isNil';
import {FormattedMessage} from 'react-intl';

import S from './error-boundary.less';
import globalMessages from 'intl/global-messages';

import type {FunctionComponent, ReactNode} from 'react';

type ErrorMessageProps = {
    message: ReactNode;
};

const ErrorMessage = ({message}: ErrorMessageProps) => {
    return <div className={S.error}>{message}</div>;
};

type ErrorBoundaryProps = {
    customErrorHandler?: FunctionComponent<{error: unknown; message: ReactNode}>;
    customErrorMessage?: ReactNode;
};

type ErrorBoundaryState = {
    caughtError: unknown;
};

/**
 * ErrorBoundary can wrap any component to prevent errors from bubbling up,
 * and will show a generic error message. Note that API calls may still show
 * an error modal, e.g. for 500 errors.
 *
 * You may pass a custom error message to show instead of the generic message.
 *
 * Alternatively, you may customize the error rendering by passing a component
 * that accepts the error as a prop.
 */
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    static defaultProps = {
        customErrorHandler: ErrorMessage,
        customErrorMessage: <FormattedMessage {...globalMessages.error} />
    };

    state = {
        caughtError: null
    };

    static getDerivedStateFromError(error) {
        return {
            caughtError: error
        };
    }

    render() {
        const {customErrorHandler, customErrorMessage, children} = this.props;
        const {caughtError} = this.state;

        let renderedComponent = children;

        if (!isNil(caughtError)) {
            const ErrorHandler = customErrorHandler;
            renderedComponent = <ErrorHandler error={caughtError} message={customErrorMessage} />;
        }

        return renderedComponent;
    }
}
