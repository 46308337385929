import PropTypes from 'prop-types';
import {shouldUpdate} from 'utils/component-utils';

import assign from 'lodash/assign';
import merge from 'lodash/merge';
import isUndefined from 'lodash/isUndefined';

const {number, object, string} = PropTypes;
import S from './progress-pulsing-circles.less';

/**
 * Indeterminate progress indicator with a row of three pulsing circles.
 *
 * @deprecated Use @material-ui/core/LinearProgress or ks-progress-circular
 */
export default class ProgressPulsingCircles extends React.Component {
    static propTypes = {
        size: number, // diameter (height) in rems
        margin: number, // margin of containing div in rems
        animationDuration: number, // in ms
        animationTimingFunction: string,
        className: string,
        style: object
    };

    static defaultProps = {
        size: 1.6,
        margin: 0.5,
        animationDuration: 1000,
        animationTimingFunction: 'ease-in-out'
    };

    shouldComponentUpdate(nextProps) {
        return shouldUpdate(this, nextProps);
    }

    render() {
        const {size, margin, animationDuration, animationTimingFunction} = this.props;

        const sizeRems = `${size}rem`;
        const containerStyle = {
            height: sizeRems,
            margin: `${margin}rem`
        };
        let circleStyle = null;

        circleStyle = {
            width: sizeRems,
            height: sizeRems,
            animationDuration: `${animationDuration}ms`,
            animationTimingFunction: animationTimingFunction
        };

        const baseDelay = Math.floor(animationDuration / 8);
        let circleStyleL = {};
        let circleStyleM = {animationDelay: `${baseDelay}ms`};
        let circleStyleR = {animationDelay: `${baseDelay * 2}ms`};
        circleStyleL = merge(circleStyleL, circleStyle);
        circleStyleM = merge(circleStyleM, circleStyle);
        circleStyleR = merge(circleStyleR, circleStyle);

        let className = S.circle;
        if (!isUndefined(this.props.className)) {
            className += ` ${this.props.className}`;
        }
        if (!isUndefined(this.props.style)) {
            circleStyleL = assign(circleStyleL, this.props.style);
            circleStyleM = assign(circleStyleM, this.props.style);
            circleStyleR = assign(circleStyleR, this.props.style);
        }

        return (
            <div style={containerStyle}>
                <span className={className} style={circleStyleL} />
                <span className={className} style={circleStyleM} />
                <span className={className} style={circleStyleR} />
            </div>
        );
    }
}
