/**
 * Parameters for identifying a user
 *
 * @typedef {Object} AppcuesUserParams
 *  @property {Immutable.Map} user
 *  @property {Immutable.Record} client
 *  @property {string} language
 *  @property {string} version
 */

/**
 *
 * Identifies the current user with Appcues
 *
 * @param {object} window The global window object
 * @param {object} logger A logger object
 * @param {AppcuesUserParams} userParams The parameters that identify the user
 * @returns true if Appcues was called successfully, false otherwise
 */
export const identifyUser = (window, logger, userParams) => {
    if (window.Appcues) {
        try {
            const {user, client, language, version} = userParams;

            window.Appcues.identify(user.get('userId'), {
                //TODO createdAt and planTier
                accountId: client.clientId,
                companyName: client.institutionName,
                role: user.getIn(['userGroups', 0, 'userGroupType', 'ref']),
                firstName: user.get('firstName'),
                email: user.get('email'),
                language,
                version
            });

            return true;
        } catch (err) {
            logger.error(`Failure when calling Appcues: ${err.message}`);
            return false;
        }
    }

    logger.error('Appcues is not initialized');
    return false;
};

/**
 * Tracks a page change within Appcues
 *
 * @param {object} window The global window object
 * @param {object} logger A logger object
 */
export const trackPageChange = (window, logger) => {
    if (window.Appcues) {
        try {
            window.Appcues.page();
            return true;
        } catch (err) {
            logger.error(`Failure when calling Appcues: ${err.message}`);
        }
    }

    logger.error('Appcues is not initialized');
    return false;
};
