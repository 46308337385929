import {combineReducers, applyMiddleware, createStore, compose} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {routerMiddleware, connectRouter} from 'connected-react-router';
import reduxThunk from 'redux-thunk';

import apiMiddleware from './middleware/api';
import externalMiddleware from './middleware/external-calls';
import socketIOMiddleware from './middleware/sockets';
import intlMiddleware from './middleware/intl';

/** @type {import('redux').ReducersMapObject} */
const GlobalReducer = {};

export let store;

export function createReducer(initialState, actionsMap) {
    return (state = initialState, action) => {
        const reduceFn = actionsMap[action.type];
        if (!reduceFn) {
            return state;
        }

        return Object.assign({}, state, reduceFn(state, action));
    };
}

export function connectReducer(name, reducer) {
    GlobalReducer[name] = reducer;

    if (!store) {
        return;
    }
    const newReducer = combineReducers(GlobalReducer);
    store.replaceReducer(newReducer);
}

export function initializeReduxStore(history) {
    const routerSyncMiddleware = routerMiddleware(history);
    const middlewares = [
        routerSyncMiddleware,
        reduxThunk,
        apiMiddleware,
        externalMiddleware,
        intlMiddleware,
        socketIOMiddleware
    ];
    const deployment = __DEPLOYMENT__;

    let enhancer = applyMiddleware(...middlewares);
    /**
     * @todo    SCLD-14919 deployment should never be undefined. It currently is
     *          undefined when running unit tests. Remove this temppoary fix.
     */
    if (deployment === undefined) {
        // do nothing. Remove this check.
    } else if (!deployment.includes('production')) {
        enhancer = composeWithDevTools(enhancer);
    } else {
        enhancer = compose(enhancer);
    }

    connectReducer('router', connectRouter(history));
    const reducer = combineReducers(GlobalReducer);

    store = createStore(reducer, enhancer);
    return store;
}
