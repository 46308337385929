export default function formControlPropTypes(PropTypes) {
    return {
        id: PropTypes.string.isRequired,
        touched: PropTypes.bool.isRequired,
        valid: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onTouched: PropTypes.func.isRequired,
        messages: PropTypes.any.isRequired,
        triedSubmit: PropTypes.any.isRequired
    };
}
