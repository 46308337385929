import * as ApiUtils from 'utils/api-utils';
import {ACTIVITY_LIMIT} from 'constants/activity-constants';

/**
 * @typedef {import('immutable').Map} ImmutableMap
 * @typedef {import('immutable').List} ImmutableList
 */

/**
 * Creates the specified users and associates them with the specified organization.
 *
 * @param {object[]} users
 * @param {string} organizationId
 * @returns {Promise<ImmutableMap>}
 */
export const bulkImportUsers = (users, organizationId) => {
    return ApiUtils.callApiMeteorAuthenticatedUnsafe({
        endpoint: '/users/bulk-import',
        method: 'post',
        data: {
            users,
            organizationId
        }
    });
};

/**
 * Log out.
 *
 * @returns {Promise}
 */
export const logout = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/user/logout',
        method: 'post'
    });
};

/**
 * Get a user.
 *
 * @param {string} userId
 * @returns {Promise<ImmutableMap>} user
 */
export const fetchUser = (userId) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}`,
        method: 'get'
    });
};

/**
 * Returns a map of userIds to signed URLs for their avatar images in S3.
 *
 * @param {string[]} userIds
 * @returns {Promise<ImmutableMap>}
 */
export const fetchUserAvatarsSignedUrls = (userIds) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/avatars/signed-urls`,
        method: 'post',
        data: {
            userIds
        }
    });
};

/**
 * Do not use this method unless you are sure you need to.
 * Instead, use fetchUsers.
 * @see {@link fetchUsers}
 * @param {Object} options
 * @returns {Promise<ImmutableList & ImmutableMap>}
 */
export const fetchUsersRawQuery = (options) => {
    const req = {
        endpoint: '/users',
        method: 'get',
        params: {
            ...options
        }
    };
    return ApiUtils.callApiMeteorAuthenticated(req);
};

/**
 * @param {Object} options
 * @returns {Promise<ImmutableList & ImmutableMap>}
 */
export const fetchUsers = (options) => {
    return fetchUsersRawQuery({
        withRoles: true,
        active: true,
        ...options
    });
};

/**
 * @param {Object} query
 * @returns {Promise<ImmutableList>}
 */
export const fetchUserGroups = (query) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/userGroups',
        method: 'get',
        params: query
    });
};

/**
 * @typedef ClobberedUser
 * @property {string} userId
 * @property {string} firstName
 * @property {string} lastName
 * @property {Function} get
 */
/**
 * Fetches active users that are filterable on userGroupTypes, userId, and courseIds
 * If a userId is passed in, a single ClobberedUser is returned.
 *
 * @param {object} options
 * @returns {Promise<import('immutable').List<ClobberedUser>>}
 */
export const fetchUsersPicklist = async (options) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users/picklist',
        method: 'get',
        params: {
            ...options
        }
    });
};

/**
 * Get activity for a user.
 *
 * @param {string} userId
 * @param {object} query
 * @returns {Promise<ImmutableList>} activity
 */
export const fetchUserActivity = (userId, query = {limit: ACTIVITY_LIMIT}) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/activity`,
        method: 'get',
        params: query
    });
};

/**
 * Get sessions for a user.
 *
 * @param {string} userId
 * @returns {Promise<ImmutableList>} sessions
 */
export const fetchUserSessions = (userId) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/sessions`,
        method: 'get'
    });
};

/**
 * Get courses for a user with the following course roles: owner, contributor, participant, sp, monitor
 *
 * @param {string} userId
 * @returns {Promise<ImmutableList>} sessions
 */
export const fetchUserCourses = (userId) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/courses`,
        method: 'get'
    });
};

/**
 * Searches for users whose names match the specified search value and user group types
 *
 * @param {string} searchValue
 * @param {Array<string>} userGroupTypes
 * @returns {Promise<ImmutableList>} users
 */
export const searchUsers = (searchValue, userGroupTypes) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users`,
        method: 'get',
        params: {
            userGroupTypes,
            active: true,
            descending: false,
            order: 'fullName',
            search: searchValue
        }
    });
};

/**
 * Update Password.
 *
 * @param {ImmutableMap} payload
 * @returns {Promise}
 */
export const updateProfilePassword = (payload) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users/profile/password',
        method: 'put',
        data: payload
    });
};

/**
 * Get a user preference by clientId and userId.
 *
 * @returns {Promise<ImmutableMap>}
 */
export const fetchUserPreferences = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/user-preferences',
        method: 'get'
    });
};

/**
 * Get a user preference by clientId and userId.
 *
 * @param {Array} payload
 * @returns {Promise<ImmutableMap>}
 */
export const updateUserPreferences = (payload) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/user-preferences',
        method: 'put',
        data: payload
    });
};

/**
 * Get the global role specified in the query for the user.
 *
 * @param {string} userId
 * @param {object} permissionGroups
 * @returns {Promise<ImmutableList>} userHasGlobalRole
 */
export const fetchUserHasGlobalRole = (userId, permissionGroups) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/has-global-role`,
        method: 'get',
        params: permissionGroups
    });
};

/**
 * Validates the specified users to determine if they can be imported.
 *
 * @param {object[]} users
 * @returns {Promise<ImmutableMap>} The result of the validation, including any errors
 */
export const validateBulkImportUsers = (users) => {
    return ApiUtils.callApiMeteorAuthenticatedUnsafe({
        endpoint: '/users/bulk-import-validate',
        method: 'post',
        data: {
            users
        }
    });
};
