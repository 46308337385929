Object.defineProperty(exports, '__esModule', {
    value: true
});

exports.default = {
    addToCourse: {
        defaultMessage: 'Add to Course',
        id: 'user_list.add_to_course'
    },
    addToCoursePodTitle: {
        defaultMessage: 'Add ({numUsers} Users) to Course',
        id: 'users.add_to_course.pod_title'
    },
    addUser: {
        defaultMessage: 'Add User',
        id: 'user_list.add_user'
    },
    archiveConfirmationHelpText: {
        defaultMessage:
            'Archived Users will no longer be able to login and will not be available to associate with any new content or sessions.',
        id: 'user_detail.archive.confirmation.helptext'
    },
    archiveConfirmationValidate: {
        defaultMessage: 'Are you sure you want to archive this User?',
        id: 'user_detail.archive.confirmation.validate'
    },
    archiveModalTitle: {
        defaultMessage: 'Archive {name}',
        id: 'user_detail.archive.title'
    },
    archivedOrganization: {
        defaultMessage: '{name} (Archived)',
        id: 'user_field.organization.archived'
    },
    avatarHelp: {
        defaultMessage: 'Choose an avatar image for this user (up to 5 MB, 500x500 pixels)',
        id: 'user_field.avatar.help'
    },
    avatarUpdate: {
        defaultMessage: 'Update user avatar image',
        id: 'user_field.avatar.update'
    },
    avatarUploadErrorFileSize: {
        defaultMessage: `Avatar file size is too large ({sizeInMB} MB)`,
        id: 'avatar.errorFileSize'
    },
    avatarUploadErrorResolution: {
        defaultMessage: `Avatar resolution is too high ({dims})`,
        id: 'avatar.errorResolution'
    },
    bulkActivateConfirmation: {
        defaultMessage: 'Are you sure you want to activate these users?',
        id: 'user_list.bulk_activate.confirmation'
    },
    bulkActivateConfirmationDisabledNoop: {
        defaultMessage: 'All selected users are already active. No action will be taken.',
        id: 'user_list.bulk_activate.confirmation_disabled_noop'
    },
    bulkActivateConfirmationDisabledUnauthorized: {
        defaultMessage:
            'You are not authorized to activate one or more of the selected users. No action will be taken.',
        id: 'user_list.bulk_activate.confirmation_disabled_unauthorized'
    },
    bulkActivateItemsToActivate: {
        defaultMessage: '{count} {count, plural, one {user} other {users}} will be activated.',
        id: 'user_list.bulk_activate.items_to_activate'
    },
    bulkActivateItemsToIgnore: {
        defaultMessage:
            '{count} {count, plural, one {user} other {users}} will be ignored because {count, plural, one {it is} other {they are}} already active.',
        id: 'user_list.bulk_activate.items_to_ignore'
    },
    bulkActivateModalTitle: {
        defaultMessage: 'Bulk Activate',
        id: 'user_list.bulk_activate.title'
    },
    bulkActivateUnauthorizedItems: {
        defaultMessage:
            '{count} {count, plural, one {user} other {users}} will be ignored because you do not have permission to activate {count, plural, one {it} other {them}}.',
        id: 'user_list.bulk_activate.unauthorized_items'
    },
    bulkArchiveConfirmation: {
        defaultMessage: 'Are you sure you want to archive these users?',
        id: 'user_list.bulk_archive.confirmation'
    },
    bulkArchiveConfirmationDisabledNoop: {
        defaultMessage: 'All selected users are already archived. No action will be taken.',
        id: 'user_list.bulk_archive.confirmation_disabled_noop'
    },
    bulkArchiveConfirmationDisabledUnauthorized: {
        defaultMessage: 'You are not authorized to archive one or more of the selected users. No action will be taken.',
        id: 'user_list.bulk_archive.confirmation_disabled_unauthorized'
    },
    bulkArchiveItemsToArchive: {
        defaultMessage: '{count} {count, plural, one {user} other {users}} will be archived.',
        id: 'user_list.bulk_archive.items_to_archive'
    },
    bulkArchiveItemsToIgnore: {
        defaultMessage:
            '{count} {count, plural, one {user} other {users}} will be ignored because they are already archived.',
        id: 'user_list.bulk_archive.items_to_ignore'
    },
    bulkArchiveModalTitle: {
        defaultMessage: 'Bulk Archive',
        id: 'user_list.bulk_archive.title'
    },
    bulkArchiveUnauthorizedItems: {
        defaultMessage:
            '{count} {count, plural, one {user} other {users}} will be ignored because you do not have permission to archive them.',
        id: 'user_list.bulk_archive.unauthorized_items'
    },
    bulkDeleteConfirmation: {
        defaultMessage: 'Are you sure you want to delete these users?',
        id: 'user_list.bulk_delete.confirmation'
    },
    bulkDeleteConfirmationDisabledNoop: {
        defaultMessage: 'All selected users are in use and already archived. No action will be taken.',
        id: 'user_list.bulk_delete.confirmation_disabled_noop'
    },
    bulkDeleteConfirmationDisabledUnauthorized: {
        defaultMessage:
            'You are not authorized to delete/archive one or more of the selected users. No action will be taken.',
        id: 'user_list.bulk_delete.confirmation_disabled_unauthorized'
    },
    bulkDeleteItemsToArchive: {
        defaultMessage: '{count} {count, plural, one {user} other {users}} will be archived because they are in use.',
        id: 'user_list.bulk_delete.items_to_archive'
    },
    bulkDeleteItemsToDelete: {
        defaultMessage: '{count} {count, plural, one {user} other {users}} will be deleted.',
        id: 'user_list.bulk_delete.items_to_delete'
    },
    bulkDeleteItemsToIgnore: {
        defaultMessage:
            '{count} {count, plural, one {user} other {users}} will be ignored because they are in use and already archived.',
        id: 'user_list.bulk_delete.items_to_ignore'
    },
    bulkDeleteModalTitle: {
        defaultMessage: 'Bulk Delete',
        id: 'user_list.bulk_delete.title'
    },
    bulkDeleteUnauthorizedItems: {
        defaultMessage:
            '{count} {count, plural, one {user} other {users}} will be ignored because you do not have permission to delete/archive them.',
        id: 'user_list.bulk_delete.unauthorized_items'
    },
    bulkEditOrganizationsWarning: {
        defaultMessage: 'Users will be added to all organizations listed below',
        id: 'users.bulkEditOrganizationsWarning'
    },
    bulkHelpText: {
        defaultMessage: 'Click the checkbox next to an attribute to edit it for all selected items.',
        description: 'Bulk Update Users helptext',
        id: 'users.bulk_update.help.text'
    },
    bulkOrganizationError: {
        defaultMessage: 'Please select at least one valid organization',
        id: 'users.bulk_update.organization.error'
    },
    bulkOrganizationHelp: {
        defaultMessage: ' ',
        id: 'users.bulk_update.organization.help'
    },
    bulkPasswordHelp: {
        defaultMessage: 'Users will be notified after resetting their passwords',
        id: 'users.bulk_update.help.password'
    },
    bulkPasswordPreserve: {
        defaultMessage: 'Preserve Passwords',
        id: 'users.bulk_update.password.preserve'
    },
    bulkPasswordReset: {
        defaultMessage: 'Reset Passwords',
        id: 'users.bulk_update.password.reset'
    },
    bulkRoleHelp: {
        defaultMessage: ' ',
        id: 'users.bulk_update.help.role'
    },
    bulkRoleHelpException: {
        defaultMessage: 'Disabled: you cannot update user groups since you are one of the selected users.',
        id: 'users.bulk_update.help.role.exception'
    },
    bulkSelfSelectedClickText: {
        defaultMessage: 'Click to remove',
        description: 'if logged in user is one of selected users',
        id: 'users.bulk_update.warning.self_selected.click_text'
    },
    bulkSelfSelectedWarning: {
        defaultMessage: 'Warning: you are one of the selected users. {removeLink} yourself from bulk update.',
        description: 'if logged in user is one of selected users',
        id: 'users.bulk_update.warning.self_selected'
    },
    bulkStatusHelp: {
        defaultMessage:
            'Set these users to inactive if you want them to be unable to login or be associated with new content.',
        id: 'users.bulk_update.help.status'
    },
    bulkUpdatePodTitle: {
        defaultMessage: 'Bulk update ({numUsers} users)',
        id: 'users.bulk_update.pod_title'
    },
    changePassword: {
        defaultMessage: 'Change Password',
        id: 'user_list.change_password'
    },
    confirmImport: {
        defaultMessage: 'Are you sure you want to import valid users from this file?',
        id: 'users_import.confirm_import'
    },
    contact: {
        defaultMessage: 'Contact',
        description: 'Title for contact pod',
        id: 'user_detail.contact'
    },
    contactLocked: {
        defaultMessage: 'This contact has been locked from editing',
        id: 'users.contactLocked'
    },
    deleteConfirmation: {
        defaultMessage: 'Are you sure you want to delete this user?',
        id: 'user_detail.delete.confirmation'
    },
    deleteModalTitle: {
        defaultMessage: 'Delete {name}',
        id: 'user_detail.delete.title'
    },
    details: {
        defaultMessage: 'Details',
        description: 'Title for Details pod',
        id: 'user_detail.details'
    },
    downloadTemplate: {
        defaultMessage: 'Download a template to add users to for subsequent import.',
        id: 'users_import.download_template'
    },
    downloadTemplateTitle: {
        defaultMessage: 'Download Template',
        id: 'users_import.download_template_title'
    },
    emailHelp: {
        defaultMessage: 'A valid email address is required for new users',
        id: 'user_field.email.help'
    },
    firstNameHelp: {
        defaultMessage: ' ',
        id: 'user_field.first_name.help'
    },
    importHelp: {
        defaultMessage: 'To import users from an xls, xlsx, or csv, select the file from your computer.',
        id: 'users_import.help'
    },
    importUpload: {
        defaultMessage: 'Upload Users',
        id: 'users_import.upload'
    },
    importUsers: {
        defaultMessage: 'Import Users',
        id: 'users.importUsers'
    },
    importUsersTip: {
        defaultMessage: 'Note: You can import up to 100 users at one time',
        id: 'users.importUsersTip'
    },
    importValidationErrorAllUsersInvalid: {
        defaultMessage:
            'All Users in the uploaded file failed validation and/or already exist.  Please check the file and try again.',
        id: 'users.importValidationErrorAllUsersInvalid'
    },
    importValidationErrorAxiosSchemaError: {
        defaultMessage:
            '{count} {count, plural, one {row has} other {rows have}} invalid email and/or username. Please ensure all emails and usernames are correctly formatted.',
        id: 'users.importValidationErrorAxiosSchemaError'
    },
    importValidationErrorColumnMissing: {
        defaultMessage:
            'The uploaded file is missing one or more columns. Expected columns are: First Name, Middle Name, Last Name, User Name, Email, User Number, and Password.',
        id: 'users.importValidationErrorColumnMissing'
    },
    importValidationErrorDupeEmails: {
        defaultMessage:
            'The following emails appear more than once in the file: {data}. Please delete the duplicates and try again.',
        id: 'users.importValidationErrorDupeEmails'
    },
    importValidationErrorDupeUserNames: {
        defaultMessage:
            'The following User names appear more than once the file: {data}. Please delete the duplicates and try again.',
        id: 'users.importValidationErrorDupeUserNames'
    },
    importValidationErrorEmpty: {
        defaultMessage: 'The uploaded file does not contain any Users. Please add Users and try again.',
        id: 'users.importValidationErrorEmpty'
    },
    importValidationErrorFileCount: {
        defaultMessage: 'Please upload only one file at time.',
        id: 'users.importValidationErrorFileCount'
    },
    importValidationErrorFileType: {
        defaultMessage: 'The uploaded file type is not supported. Supported file types are: csv, xls and xlsx.',
        id: 'users.importValidationErrorFileType'
    },
    importValidationErrorOnRow: {
        defaultMessage: 'Error(s) found on row {row}',
        id: 'users.importValidationErrorOnRow'
    },
    importValidationErrorRowText: {
        defaultMessage:
            '{count} {count, plural, one {row is} other {rows are}} missing required data. Please ensure all rows have: First Name, Last Name, User Name, Email, and Password.',
        id: 'users.importValidationErrorRowText'
    },
    importValidationErrorTooManyUsers: {
        defaultMessage:
            'Too many users were included in the imported sheet. Please reduce the number of users included in the sheet and try again.',
        id: 'users.importValidationErrorTooManyUsers'
    },
    importValidationErrorUnknown: {
        defaultMessage: 'There was a problem with the file. Please check the file and upload again.',
        id: 'users.importValidationErrorUnknown'
    },
    importingUsersFromFile: {
        defaultMessage: 'Importing Users from file: {fileName}',
        id: 'users.importingUsersFromFile'
    },
    lastActivity: {
        defaultMessage: 'Last Activity',
        id: 'user_field.last_activity'
    },
    lastLogin: {
        defaultMessage: 'Logged in',
        id: 'user_field.last_login'
    },
    lastNameHelp: {
        defaultMessage: ' ',
        id: 'user_field.last_name.help'
    },
    loadingCourses: {
        defaultMessage: 'Loading courses...',
        description: 'Courses are being loaded from backend',
        id: 'user_detail.courses.loading'
    },
    loadingSessions: {
        defaultMessage: 'Loading sessions...',
        description: 'Sessions are being loaded from backend',
        id: 'user_detail.sessions.loading'
    },
    middleNameHelp: {
        defaultMessage: ' ',
        id: 'user_field.middle_name.help'
    },
    nameFields: {
        defaultMessage: 'Full Name',
        description: 'Field names for full name',
        id: 'user_detail.first_middle_last_name'
    },
    noActivities: {
        defaultMessage: "This user doesn't have any activity yet",
        description: 'User has no recent activities',
        id: 'user_detail.sidebar.no_activity'
    },
    noCourses: {
        defaultMessage: 'User is not a member of any visible courses',
        description: 'User has no associated courses',
        id: 'user_detail.courses.no_courses'
    },
    noLastActivity: {
        defaultMessage: 'No activity recorded.',
        id: 'user_list.no_last_activity'
    },
    noSessions: {
        defaultMessage: 'User has not participated in any visible sessions',
        description: 'User has no associated sessions',
        id: 'user_detail.sessions.no_sessions'
    },
    notify: {
        defaultMessage: 'Notify',
        id: 'user_detail.notify'
    },
    organizationError: {
        defaultMessage: 'Please select a valid organization.',
        id: 'user_field.organization.error'
    },
    organizationHelp: {
        defaultMessage: 'Does this user belong to one or multiple groups using the system?',
        id: 'user_field.organization.help'
    },
    passwordHelp: {
        defaultMessage: 'Passwords require at least 3 characters and cannot contain spaces',
        id: 'user_field.password.help'
    },
    passwordHelpMedium: {
        defaultMessage:
            'Passwords must contain at least one capitalized letter, one lowercase letter, and one number (mimimum 8 characters)',
        id: 'user_new.password.help.medium'
    },
    passwordHelpNone: {
        defaultMessage: 'Passwords can contain any combination of letters, numbers and special characters',
        id: 'user_new.password.help.none'
    },
    passwordHelpStrong: {
        defaultMessage:
            'Passwords must contain at least one capitalized letter, one lowercase letter, one number, and one special character (minimum 10 characters)',
        id: 'user_new.password.help.strong'
    },
    resetPassword: {
        defaultMessage: 'Reset Password',
        id: 'user_list.reset_password'
    },
    resetPasswordFailure: {
        defaultMessage: 'Unable to reset password for {name}. Please try again.',
        id: 'user_list.reset_password.failure'
    },
    resetPasswordNotifyBody: {
        defaultMessage: 'Your password needs to be reset. Please login to continue.',
        id: 'user_list.reset_password.notify.body'
    },
    resetPasswordNotifyTitle: {
        defaultMessage: 'Password Reset',
        id: 'user_list.reset_password.notify.title'
    },
    resetPasswordSuccess: {
        defaultMessage: 'A message has been sent to {name}',
        id: 'user_list.reset_password.success'
    },
    resetPasswordTitle: {
        defaultMessage: 'Password Reset',
        id: 'user_list.reset_password.title'
    },
    searchPlaceholder: {
        defaultMessage: 'Search usernames and full names',
        id: 'users.search.placeholder'
    },
    seeAllCourses: {
        defaultMessage: 'See All Courses',
        description: 'Button text to see all courses',
        id: 'user_detail.courses.see_all'
    },
    seeAllSessions: {
        defaultMessage: 'See All Sessions',
        description: 'Link text to see all sessions',
        id: 'user_detail.sessions.see_all'
    },
    seeUsers: {
        defaultMessage: 'View imported Users',
        id: 'users_import.see_users'
    },
    ssoExternalId: {
        defaultMessage: 'SSO External ID',
        id: 'users.sso_external_id'
    },
    ssoExternalIdHelp: {
        defaultMessage:
            'The unique identifier (usually a username or email address) used by your Single Sign-On provider for this user. Warning: exercise caution when editing this setting, as an incorrect configuration can prevent the user from being able to log in.',
        id: 'users.sso_external_id.help'
    },
    statusHelp: {
        defaultMessage:
            'Set this user to inactive if you want them to be unable to login or be associated with new content',
        id: 'user_field.status.help'
    },
    titleAddToCourse: {
        defaultMessage: 'Add Users to Course',
        id: 'users.title.add_to_course'
    },
    titleBulkUpdate: {
        defaultMessage: 'Bulk Update Users',
        id: 'users.title.bulk_update'
    },
    titleDetail: {
        defaultMessage: '{data}',
        id: 'users.title.detail'
    },
    titleDetailEditContact: {
        defaultMessage: 'Edit Contact - {data}',
        id: 'users.title.detail.edit_contact'
    },
    titleDetailEditDetails: {
        defaultMessage: 'Edit Details - {data}',
        id: 'users.title.detail.edit_details'
    },
    titleImport: {
        defaultMessage: 'Import Users',
        id: 'users.title.import'
    },
    titleNew: {
        defaultMessage: 'Add New User',
        id: 'users.title.new'
    },
    userGroupError: {
        defaultMessage: 'Please select a valid role.',
        id: 'user_field.user_group.error'
    },
    userGroupHelp: {
        defaultMessage: 'Roles determine global permission and access rights',
        id: 'user_field.user_group.help'
    },
    userListDownloadModalTitle: {
        defaultMessage: 'Downloading User List',
        id: 'user_list.download.title'
    },
    userManagedByLms: {
        defaultMessage:
            'As a result of integration, this user is managed within your Learning Management System (LMS).',
        id: 'users.managedByLms'
    },
    userNameHelp: {
        defaultMessage: ' ',
        id: 'user_field.user_name.help'
    },
    userNumber: {
        defaultMessage: 'User Number',
        id: 'user_field.user_number'
    },
    userNumberHelp: {
        defaultMessage: 'A user number could be an institution ID or other identifier',
        id: 'user_field.user_number.help'
    },
    usersImported: {
        defaultMessage: '{count} {count, plural, =0 {Users} one {User} other {Users}} imported successfully.',
        id: 'users_import.users_imported'
    },
    usersToImport: {
        defaultMessage: '{count} {count, plural, =0 {Users} one {User} other {Users}} will be imported.',
        id: 'users_import.users_to_import'
    },
    usersToImportWithFailures: {
        defaultMessage:
            '{count} {count, plural, =0 {Users} one {User} other {Users}} will be imported. {countFailed} {countFailed, plural, =0 {Users} one {User} other {Users}} failed validation.',
        id: 'users.usersToImportWithFailures'
    }
};
