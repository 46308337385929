export default function createFormManagerShape(PropTypes) {
    return PropTypes.shape({
        addControl: PropTypes.func.isRequired,
        removeControl: PropTypes.func.isRequired,
        isRegistered: PropTypes.func.isRequired,
        onChanged: PropTypes.func.isRequired,
        onInitialized: PropTypes.func.isRequired,
        onTouched: PropTypes.func.isRequired,
        updateValidity: PropTypes.func.isRequired
    });
}
