import Immutable from 'immutable';

/**
 * @typedef {import('constants/error-codes').ErrorCode} ErrorCode
 * @typedef {import('immutable').Map} Map
 */

/*
 * The Immutable conversion here to JS is probably just for cases where we are passing in a Meteor Error and not an
 * actual ErrorCode object. This might have only been done in `reset-password`, but it is safer just to leave as is even
 * if it is not doing anything.
 */

/**
 * @param {ErrorCode|Map} errorCodeA
 * @param {ErrorCode|Map} errorCodeB
 * @returns {boolean}
 */
export const errorCodesAreEqual = (errorCodeA, errorCodeB) => {
    const a = Immutable.isImmutable(errorCodeA) ? errorCodeA.toJS() : errorCodeA;
    const b = Immutable.isImmutable(errorCodeB) ? errorCodeB.toJS() : errorCodeB;
    return a.errorCode === b.errorCode;
};

export default {
    errorCodesAreEqual
};
