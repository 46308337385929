Object.defineProperty(exports, '__esModule', {
    value: true
});

exports.default = {
    AfterSession: {
        defaultMessage: 'After Session',
        id: 'generic.evaluation_time.after_session'
    },
    AfterSessionEMR: {
        defaultMessage: 'After Session EMR',
        id: 'generic.evaluation_time.after_session_emr'
    },
    BeforeSession: {
        defaultMessage: 'Before Session',
        id: 'generic.evaluation_time.before_session'
    },
    BeforeSessionEMR: {
        defaultMessage: 'Before Session EMR',
        id: 'generic.evaluation_time.before_session_emr'
    },
    Complete: {
        defaultMessage: 'Complete',
        description: 'Status of course, the key matches the DB ref',
        id: 'course_status.complete'
    },
    Development: {
        defaultMessage: 'In Development',
        description: 'Status of course, the key matches the DB ref',
        id: 'course_status.in_development'
    },
    DuringSession: {
        defaultMessage: 'Administrator',
        id: 'generic.evaluation_time.during_session'
    },
    InProgress: {
        defaultMessage: 'In Progress',
        description: 'Status of course, the key matches the DB ref',
        id: 'course_status.in_progress'
    },
    ManualScenario: {
        defaultMessage: 'Standalone Scenario',
        id: 'generic.evaluation_time.manual_scenario'
    },
    Monitor: {
        defaultMessage: 'Monitor',
        id: 'generic.evaluation_time.monitor'
    },
    PatientExam: {
        defaultMessage: 'Patient Exam Flow',
        id: 'generic.evaluation_time.patient_exam'
    },
    PatientExamFollowUp: {
        defaultMessage: 'Patient Exam Flow - Follow Up {followUpNum}',
        id: 'generic.evaluation_time.patient_exam_follow_up'
    },
    Question: {
        defaultMessage: 'Question',
        id: 'generic.question.capitalized'
    },
    Reflection: {
        defaultMessage: 'Reflection',
        id: 'generic.evaluation_time.reflection'
    },
    StudentExam: {
        defaultMessage: 'Student Exam Flow',
        id: 'generic.evaluation_time.student_exam'
    },
    StudentExamFollowUp: {
        defaultMessage: 'Student Exam Flow - Follow Up {followUpNum}',
        id: 'generic.evaluation_time.student_exam_follow_up'
    },
    SupportedFileTypesAudio: {
        defaultMessage: 'Audio: .mp3',
        id: 'questions.add_media.supported_file_types_audio'
    },
    SupportedFileTypesImages: {
        defaultMessage: 'Images: .gif, .jpg, .jpeg, .png',
        id: 'questions.add_media.supported_file_types_images'
    },
    SupportedFileTypesText: {
        defaultMessage: 'Supported file types',
        id: 'questions.add_media.supported_file_types_text'
    },
    SupportedFileTypesVideo: {
        defaultMessage: 'Video: .mp4, .m4v, .mov',
        id: 'questions.add_media.supported_file_types_video'
    },
    accessibility: {
        defaultMessage: 'Accessibility',
        id: 'generic.accessibility'
    },
    actionCannotBeUndone: {
        defaultMessage: 'This action cannot be undone.',
        id: 'generic.actionCannotBeUndone'
    },
    activate: {
        defaultMessage: 'Activate',
        id: 'generic.activate'
    },
    active: {
        defaultMessage: 'Active',
        id: 'generic.active'
    },
    activeUsersCount: {
        defaultMessage: 'Active users ({count})',
        id: 'generic.activeUsersWithCount'
    },
    add: {
        defaultMessage: 'Add',
        id: 'generic.add'
    },
    addA: {
        defaultMessage: 'Add a {itemType}',
        id: 'generic.add_a'
    },
    addDetails: {
        defaultMessage: 'Add Details',
        id: 'reservation_new_modal.addDetails'
    },
    addFollowUp: {
        defaultMessage: 'Add Follow-Up',
        id: 'generic.addFollowUp'
    },
    addMedia: {
        defaultMessage: 'Add Media',
        id: 'questions.add_media'
    },
    addNewItem: {
        defaultMessage: 'Add New {itemType}',
        id: 'generic.addNewItem'
    },
    addQuestion: {
        defaultMessage: 'Add question',
        id: 'emr_template_builder.add_question'
    },
    addRowWithColumns: {
        defaultMessage: 'Add row with columns:',
        id: 'emr_template_builder.add_row_with_columns'
    },
    administrator: {
        defaultMessage: 'Administrator',
        id: 'generic.administrator'
    },
    advanceWhenTimerExpires: {
        defaultMessage: 'Automatically advance room after timer expires',
        id: 'examFlow.advanceWhenTimerExpires'
    },
    agenda: {
        defaultMessage: 'Agenda',
        id: 'generic.agenda'
    },
    all: {
        defaultMessage: 'All',
        id: 'global.all'
    },
    allWithCount: {
        defaultMessage: 'All ({count})',
        id: 'global.allWithCount'
    },
    allowMultipleLearners: {
        defaultMessage: 'Allow multiple learners',
        id: 'allow_multiple_learners'
    },
    annotate: {
        defaultMessage: 'annotate',
        id: 'sessions.viewer.annotate'
    },
    annotation: {
        defaultMessage: 'Annotation',
        id: 'generic.annotation'
    },
    annotations: {
        defaultMessage: 'Annotations',
        id: 'generic.annotations'
    },
    anonymous: {
        defaultMessage: 'Anonymous',
        id: 'global.anonymous'
    },
    answerDisplayType: {
        defaultMessage: 'Answer display type',
        id: 'evaluations_download_options_modal.answerDisplayType'
    },
    answerLimit: {
        defaultMessage: 'Answer Limit',
        id: 'questions.answer_limit'
    },
    answerOptionLayout: {
        defaultMessage: 'Answer Options Layout',
        id: 'questions.answer_option_layout'
    },
    answerPlaceholder: {
        defaultMessage: 'Enter Answer Here',
        id: 'questions.answer_placeholder'
    },
    answerPoints: {
        defaultMessage: 'Answer Points',
        id: 'questions.answer_points'
    },
    answerText: {
        defaultMessage: 'Answer Option Text',
        id: 'questions.answer_text'
    },
    answerTypePointValue: {
        defaultMessage: 'Point value',
        id: 'evaluations_download_options_modal.pointValue'
    },
    answerTypeText: {
        defaultMessage: 'Text',
        id: 'evaluations_download_options_modal.text'
    },
    apply: {
        defaultMessage: 'Apply',
        id: 'generic.apply'
    },
    archive: {
        defaultMessage: 'Archive',
        id: 'generic.archive'
    },
    archived: {
        defaultMessage: 'Archived',
        id: 'generic.archived'
    },
    assign: {
        defaultMessage: 'Assign',
        id: 'generic.assign'
    },
    attachmentNamePlaceholder: {
        defaultMessage: 'Attachment name',
        id: 'generic.attachments.name_placeholder'
    },
    attachments: {
        defaultMessage: 'Attachments',
        id: 'generic.attachments'
    },
    audioAnnouncements: {
        defaultMessage: 'Audio Announcements',
        id: 'audioAnnouncements'
    },
    audioAnnouncementsDisabledNoMessages: {
        defaultMessage: 'No audio messages have been created.  Please contact your system administrator.',
        id: 'audioAnnouncements.disabled_no_messages'
    },
    audioIn: {
        defaultMessage: 'Microphone',
        id: 'audioVideoSelector.audioIn'
    },
    audioMessage: {
        defaultMessage: 'Audio Message',
        id: 'audioMessage'
    },
    audioOut: {
        defaultMessage: 'Speaker',
        id: 'audioVideoSelector.audioOut'
    },
    autoAdvance: {
        defaultMessage: 'Auto advance',
        id: 'auto_advance'
    },
    avatar: {
        defaultMessage: 'Avatar',
        description: 'User avatar image',
        id: 'generic.avatar'
    },
    back: {
        defaultMessage: 'Back',
        id: 'generic.back'
    },
    badDataErrorBody: {
        defaultMessage: 'The server was unable to create a new entry.',
        id: 'errors.bad_data.body'
    },
    badDataErrorBodyRecheck: {
        defaultMessage: 'Please re-check your provided data.',
        id: 'errors.bad_data.body.recheck'
    },
    badRequestErrorBody: {
        defaultMessage: 'The server rejected your request.',
        id: 'errors.bad_request.body'
    },
    boxAndWhiskers: {
        firstQuartileLabel: {
            defaultMessage: 'Q1: {value}',
            id: 'generic.reports.box_and_whiskers.label.first_quartile'
        },
        maximumLabel: {
            defaultMessage: 'Maximum: {value}',
            id: 'generic.reports.box_and_whiskers.label.maximum'
        },
        medianLabel: {
            defaultMessage: 'Median: {value}',
            id: 'generic.reports.box_and_whiskers.label.median'
        },
        minimumLabel: {
            defaultMessage: 'Minimum: {value}',
            id: 'generic.reports.box_and_whiskers.label.minimum'
        },
        thirdQuartileLabel: {
            defaultMessage: 'Q3: {value}',
            id: 'generic.reports.box_and_whiskers.label.third_quartile'
        },
        tooltipTitle: {
            defaultMessage: '{index}. {label}',
            id: 'generic.reports.box_and_whiskers.label.tooltip_title'
        }
    },
    calendar: {
        defaultMessage: 'Calendar',
        id: 'generic.calendar'
    },
    cancel: {
        defaultMessage: 'Cancel',
        id: 'generic.cancel'
    },
    cancelQuestionMove: {
        defaultMessage: 'Click to cancel question move',
        description: 'Cancellation text displayed on the fixed footer when moving a question',
        id: 'emr_template_builder.move_question.cancel_text'
    },
    cannotBeEmpty: {
        defaultMessage: 'Cannot be empty.',
        id: 'generic.error.empty'
    },
    cannotPrintWithoutEmail: {
        defaultMessage: 'You must have a valid email address to export a printable version of this item.',
        id: 'cannotPrintWithoutEmail'
    },
    captureHost: {
        defaultMessage: 'Capture Host',
        id: 'generic.captureHost'
    },
    centerSignIn: {
        defaultMessage: 'Center Sign-In',
        id: 'center_sign_in'
    },
    characterLimit: {
        defaultMessage: 'Character Limit',
        id: 'questions.character_limit'
    },
    checklists: {
        defaultMessage: 'Checklists',
        id: 'generic.checklists'
    },
    chooseDateInstructions: {
        defaultMessage: 'Choose a new date to copy this scheduled event.',
        id: 'reservation_copy_modal.choose_date_instructions'
    },
    chooseStartDateInstructions: {
        defaultMessage: 'Choose a new start date to copy this scheduled event.',
        id: 'rotational_event_copy_modal.choose_start_date_instructions'
    },
    clear: {
        defaultMessage: 'Clear',
        id: 'global.clear'
    },
    clearAll: {
        defaultMessage: 'clear all',
        id: 'data_toggler.clear_all'
    },
    clearSearch: {
        defaultMessage: 'Clear search',
        id: 'clearSearch'
    },
    click: {
        defaultMessage: 'Click',
        id: 'generic.click'
    },
    clickToEdit: {
        defaultMessage: 'Click to Edit',
        id: 'generic.clickToEdit'
    },
    clickToView: {
        defaultMessage: 'Click to view',
        id: 'generic.click_to_view'
    },
    close: {
        defaultMessage: 'Close',
        id: 'generic.close'
    },
    collapse: {
        defaultMessage: 'Collapse',
        id: 'global.collapse'
    },
    coming_soon: {
        defaultMessage: 'Coming Soon!',
        id: 'generic.coming_soon'
    },
    company: {
        defaultMessage: 'Company',
        id: 'generic.company'
    },
    completedAtDateTime: {
        defaultMessage: 'Completed {dateTime}',
        id: 'completedAtDateTime'
    },
    confirm: {
        defaultMessage: 'Confirm',
        id: 'generic.confirm'
    },
    confirmChanges: {
        defaultMessage: 'Confirm changes',
        id: 'generic.confirmChanges'
    },
    confirmPassword: {
        defaultMessage: 'Confirm new password',
        id: 'password.confirm'
    },
    confirmPasswordHelp: {
        defaultMessage: 'Re-enter new password',
        id: 'password.confirm.help'
    },
    conflictErrorBody: {
        defaultMessage: 'The server rejected your request because there was a name conflict.',
        id: 'errors.conflict.body'
    },
    connectionErrorText: {
        defaultMessage:
            'We are not able to connect to the recording device. Please verify that you are on the same network as the recording device.',
        id: 'sessions.viewer.connection_error_text'
    },
    connectionLostWithEval: {
        defaultMessage:
            'Disconnect detected. Please reconnect to continue. If you have an evaluation in progress, it will be saved.',
        id: 'global.connectionLostWithEval'
    },
    connectionReconnectText: {
        defaultMessage: 'Connection has been lost.',
        id: 'sessions.viewer.connection_reconnect_text'
    },
    contactAdministratorText: {
        defaultMessage: 'Please contact an administrator for further assistance.',
        id: 'generic.contactAdministrator_text'
    },
    continueText: {
        defaultMessage: 'Continue',
        id: 'continue_text'
    },
    contributor: {
        defaultMessage: 'Contributor',
        id: 'generic.contributor'
    },
    contributors: {
        defaultMessage: 'Contributors',
        id: 'generic.contributors'
    },
    control: {
        defaultMessage: 'Control',
        id: 'generic.control'
    },
    copied: {
        defaultMessage: 'Copied!',
        id: 'generic.copied'
    },
    copy: {
        defaultMessage: 'Copy',
        id: 'generic.copy'
    },
    copyItem: {
        defaultMessage: 'Copy {itemType}',
        id: 'generic.copyItem'
    },
    copyParticipants: {
        defaultMessage: 'Copy participants',
        id: 'reservation_copy_modal.copy_participants'
    },
    copyReservation: {
        defaultMessage: 'Copy Reservation: {title}',
        id: 'reservation_copy_modal.copy_reservation'
    },
    copyRotationalEvent: {
        defaultMessage: 'Copy Rotational Event: {title}',
        id: 'rotational_event_copy_modal.copy_rotational_event'
    },
    copySupportStaff: {
        defaultMessage: 'Copy support staff/faculty',
        id: 'reservation_copy_modal.copy_support_staff'
    },
    copyToClipboard: {
        defaultMessage: 'Copy to clipboard',
        id: 'copy_to_clipboard'
    },
    course: {
        defaultMessage: 'Course',
        id: 'generic.course'
    },
    courseHelpText: {
        defaultMessage: 'Course for this Reservation',
        id: 'reservation_new_form.course.help_text'
    },
    coursePlaceholder: {
        defaultMessage: 'Select Course',
        id: 'course_input.placeholder'
    },
    courseReleaseConfig: {
        defaultMessage: 'Course release',
        id: 'schedule.room_setup.courseReleaseConfig'
    },
    courseScenario: {
        defaultMessage: 'Course & Scenario: {courseScenarioTitle}',
        id: 'schedule.event.course_scenario'
    },
    courseScenarioTitle: {
        defaultMessage: '{courseTitle}-{scenarioTitle}',
        id: 'schedule.event.course_scenario_title'
    },
    courseStatus: {
        defaultMessage: 'Course Status',
        id: 'global.courseStatus'
    },
    courseTitle: {
        defaultMessage: 'Course: {courseTitle}',
        id: 'global.courseTitle'
    },
    courses: {
        defaultMessage: 'Courses',
        id: 'generic.courses'
    },
    createReservation: {
        defaultMessage: 'Create Reservation',
        id: 'reservation_new_modal.createReservation'
    },
    created: {
        defaultMessage: 'Created',
        id: 'generic.created'
    },
    criticalFailQuestion: {
        defaultMessage: 'Critical Fail Question',
        id: 'questions.critical_fail'
    },
    curriculum: {
        defaultMessage: 'Curriculum',
        id: 'generic.curriculum'
    },
    date: {
        defaultMessage: 'Date',
        id: 'generic.date'
    },
    dateAtTime: {
        defaultMessage: ' at ',
        id: 'time.at'
    },
    dateError: {
        defaultMessage: 'Start time must precede end time',
        id: 'reservation_new_form.date_error'
    },
    dateErrorReservationCopy: {
        defaultMessage: 'New start date cannot be the same as current start date.',
        id: 'reservation_copy_modal.date_error'
    },
    dateRangeFromTo: {
        defaultMessage: '{startDate} to {endDate}',
        id: 'dateRangeFromTo'
    },
    dateTime: {
        defaultMessage: '{date}\u00a0{time}',
        id: 'time.date_time'
    },
    dateTimeRange: {
        defaultMessage: '{startDate} {startTime} - {endDate} {endTime}',
        id: 'dateTimeRange'
    },
    day: {
        defaultMessage: 'Day',
        id: 'time.day'
    },
    days: {
        defaultMessage: 'Days',
        id: 'time.days'
    },
    daysAfter: {
        defaultMessage: '{days} days after {event}',
        id: 'time.days_after'
    },
    daysAgo: {
        defaultMessage: '{days} days ago',
        id: 'time.days.ago'
    },
    daysBefore: {
        defaultMessage: '{days} days before {event}',
        id: 'time.days_before'
    },
    default: {
        defaultMessage: 'Default',
        id: 'generic.default'
    },
    defaultAudioInLabel: {
        defaultMessage: 'microphone {num}',
        id: 'audioVideoSelector.defaultAudioInLabel'
    },
    defaultAudioOutLabel: {
        defaultMessage: 'speaker {num}',
        id: 'audioVideoSelector.defaultAudioOutLabel'
    },
    defaultVideoInLabel: {
        defaultMessage: 'camera {num}',
        id: 'audioVideoSelector.defaultVideoInLabel'
    },
    delete: {
        defaultMessage: 'Delete',
        id: 'generic.delete'
    },
    details: {
        defaultMessage: 'Details',
        id: 'generic.details'
    },
    disabled: {
        defaultMessage: 'Disabled',
        id: 'disabled'
    },
    doNotInclude: {
        defaultMessage: 'Do not include',
        id: 'evaluations_download_options_modal.do_not_include'
    },
    documentTitleWithSimCapture: {
        defaultMessage: '{documentTitle} | SimCapture',
        description: 'Appends the SimCapture app title to a view-specific document title.',
        id: 'generic.document_title.with_simcapture'
    },
    done: {
        defaultMessage: 'Done',
        id: 'generic.done'
    },
    doorNote: {
        defaultMessage: 'Door Note',
        id: 'generic.door-note'
    },
    doorNoteFollowUp: {
        defaultMessage: 'Door Note - Follow Up {followUpNum}',
        id: 'generic.phase.door_note_follow_up'
    },
    doorNotes: {
        defaultMessage: 'Door Notes',
        id: 'door_notes'
    },
    doorNotesInformation: {
        defaultMessage: 'Information',
        id: 'door_notes.information'
    },
    doorNotesSelectError: {
        defaultMessage: 'Must select a door note.',
        id: 'door_notes.selectError'
    },
    download: {
        defaultMessage: 'Download',
        id: 'generic.download'
    },
    downloadOptions: {
        defaultMessage: 'Download Options',
        id: 'evaluations_download_options_modal.title'
    },
    downloaded: {
        defaultMessage: 'Downloaded',
        id: 'downloaded'
    },
    dropdownAnswerPlaceholder: {
        defaultMessage: 'Please choose an answer.',
        id: 'questions.multiple_choice.dropdown_placeholder'
    },
    duplicate: {
        defaultMessage: 'Duplicate',
        id: 'generic.duplicate'
    },
    duration: {
        defaultMessage: 'Duration',
        id: 'generic.duration'
    },
    edit: {
        defaultMessage: 'Edit',
        id: 'generic.edit'
    },
    email: {
        defaultMessage: 'Email',
        id: 'generic.email'
    },
    emailAddressMissingBody: {
        defaultMessage: 'You do not have an email address. Please update your profile before sending notifications.',
        id: 'email_address_missing.body'
    },
    emailAddressMissingSubject: {
        defaultMessage: 'Email address missing',
        id: 'email_address_missing.subject'
    },
    emailErrorAlreadyExists: {
        defaultMessage: 'Email already exists.',
        id: 'generic.email.error.already_exists'
    },
    emailErrorFormat: {
        defaultMessage: 'Please enter a valid email address.',
        id: 'generic.email.error.format'
    },
    empty: {
        defaultMessage: 'No items are selected for this operation',
        id: 'no_bulk_selection'
    },
    emptyCourses: {
        defaultMessage: 'No courses are selected for this operation',
        id: 'no_bulk_selection.courses'
    },
    emptyReturnCourses: {
        defaultMessage: 'Return to Courses',
        id: 'no_bulk_selection.courses.return'
    },
    emptyReturnScenarios: {
        defaultMessage: 'Return to Scenarios',
        id: 'no_bulk_selection.scenarios.return'
    },
    emptyReturnSessions: {
        defaultMessage: 'Return to Sessions',
        id: 'no_bulk_selection.sessions.return'
    },
    emptyReturnUsers: {
        defaultMessage: 'Return to Users',
        id: 'no_bulk_selection.users.return'
    },
    emptyScenarios: {
        defaultMessage: 'No scenarios are selected for this operation',
        id: 'no_bulk_selection.scenarios'
    },
    emptySessions: {
        defaultMessage: 'No sessions are selected for this operation',
        id: 'no_bulk_selection.sessions'
    },
    emptyUsers: {
        defaultMessage: 'No users are selected for this operation',
        id: 'no_bulk_selection.users'
    },
    emr: {
        defaultMessage: 'Exam EMR',
        id: 'exam_emr'
    },
    emrs: {
        defaultMessage: 'EMRs',
        id: 'emrs'
    },
    enabled: {
        defaultMessage: 'Enabled',
        id: 'enabled'
    },
    encounter: {
        defaultMessage: 'Encounter',
        id: 'encounter'
    },
    encounters: {
        defaultMessage: 'Encounters',
        id: 'encounters'
    },
    endDate: {
        defaultMessage: 'End Date',
        id: 'generic.end_date'
    },
    endTime: {
        defaultMessage: 'End Time',
        id: 'generic.end_time'
    },
    error: {
        defaultMessage: 'Error',
        id: 'error'
    },
    errorCodeUnknown: {
        defaultMessage: 'An unknown error occurred. Please try again later.',
        id: 'error_code.unknown'
    },
    errorCodes: {
        BAD_DATA: {
            defaultMessage: 'Unable to Create New Entry',
            id: 'error_codes.BAD_DATA'
        },
        BAD_REQUEST: {
            defaultMessage: 'Bad Request',
            id: 'error_codes.BAD_REQUEST'
        },
        BAD_REQUEST_VALIDATION: {
            defaultMessage: 'Bad Request',
            id: 'error_codes.BAD_REQUEST_VALIDATION'
        },
        CONFLICT: {
            defaultMessage: 'Bad Request',
            id: 'error_codes.CONFLICT'
        },
        EVALUATION_NOT_FOUND: {
            defaultMessage: 'Not Found',
            id: 'error_codes.EVALUATION_NOT_FOUND'
        },
        EVALUATION_TEMPLATE_NOT_FOUND: {
            defaultMessage: 'Not Found',
            id: 'error_codes.EVALUATION_TEMPLATE_NOT_FOUND'
        },
        INTERNAL_SERVER_ERROR: {
            defaultMessage: 'Internal Server Error',
            id: 'error_codes.INTERNAL_SERVER_ERROR'
        },
        NOT_FOUND: {
            defaultMessage: 'Not Found',
            id: 'error_codes.NOT_FOUND'
        },
        RECORDING_ANNOTATION_NOT_FOUND: {
            defaultMessage: 'Not Found',
            id: 'error_codes.RECORDING_ANNOTATION_NOT_FOUND'
        },
        RESERVATION_NOT_FOUND: {
            defaultMessage: 'Not Found',
            id: 'error_codes.RESERVATION_NOT_FOUND'
        },
        SCENARIO_NOT_FOUND: {
            defaultMessage: 'Not Found',
            id: 'error_codes.SCENARIO_NOT_FOUND'
        },
        SESSION_NOT_FOUND: {
            defaultMessage: 'Not Found',
            id: 'error_codes.SESSION_NOT_FOUND'
        },
        UNKNOWN_ERROR: {
            defaultMessage: 'Unknown Error',
            id: 'error_codes.UNKNOWN_ERROR'
        }
    },
    errorComponentFailedToRender: {
        defaultMessage: 'An unexpected error occurred in this component.',
        id: 'errorComponentFailedToRender'
    },
    errorIdMessage: {
        defaultMessage: 'Error ID: {traceId}',
        id: 'errors.unknown_error.id_message'
    },
    errorNoTraceId: {
        defaultMessage: 'Trace ID is not available.',
        id: 'errors.unknown_error.no_id_message'
    },
    errorRefresh: {
        defaultMessage: 'Critical error. Please reload your browser.',
        id: 'error.refresh'
    },
    et_build: {
        defaultMessage: 'Build',
        id: 'generic.evaluation_template.build'
    },
    et_preview: {
        defaultMessage: 'Preview',
        id: 'generic.evaluation_template.preview'
    },
    evaluateeLabel: {
        defaultMessage: 'Evaluatee: ',
        id: 'global.evaluation.scoring.edit.evaluatee_label'
    },
    evaluation: {
        defaultMessage: 'Evaluation',
        id: 'generic.evaluation'
    },
    evaluationTemplateSelectError: {
        defaultMessage: 'Must select an evaluation.',
        id: 'evaluationTemplate.selectError'
    },
    evaluationTime: {
        defaultMessage: 'Evaluation Type',
        id: 'generic.evaluation_time'
    },
    evaluationTypePdlLive: {
        defaultMessage: 'Live (P2P)',
        id: 'evaluationTypePdlLive'
    },
    evaluationTypePdlPostSession: {
        defaultMessage: 'After Session (P2P)',
        id: 'evaluationTypePdlPostSession'
    },
    evaluationTypePdlPreSession: {
        defaultMessage: 'Before Session (P2P)',
        id: 'evaluationTypePdlPreSession'
    },
    evaluationTypeSelfRecordPostSession: {
        defaultMessage: 'After Session (Self-Record)',
        id: 'evaluationTypeSelfRecordPostSession'
    },
    evaluationTypeSelfRecordPreSession: {
        defaultMessage: 'Before Session (Self-Record)',
        id: 'evaluationTypeSelfRecordPreSession'
    },
    evaluationTypeSelfRecorded: {
        defaultMessage: 'Self-Record (Required)',
        id: 'evaluationTypeSelfRecorded'
    },
    evaluations: {
        defaultMessage: 'Evaluations',
        id: 'generic.evaluations'
    },
    evaluatorLabel: {
        defaultMessage: 'Evaluator: ',
        id: 'global.evaluation.scoring.edit.evaluator_label'
    },
    eventNameAndTimeRange: {
        defaultMessage: '{eventName}, {timeRange}',
        id: 'schedule.event.event_name_time_range'
    },
    eventOnDate: {
        defaultMessage: ' on ',
        id: 'time.on'
    },
    exitMinimize: {
        defaultMessage: 'Exit Minimize',
        id: 'trackbar.exit_minimize'
    },
    expand: {
        defaultMessage: 'Expand',
        id: 'global.expand'
    },
    expandAssetTitle: {
        defaultMessage: 'Preview',
        id: 'questions.expand_asset_title'
    },
    expired: {
        defaultMessage: 'Your password has expired. Please create a new password in order to continue.',
        id: 'password.expired'
    },
    export: {
        defaultMessage: 'Export',
        id: 'generic.export'
    },
    exportScheduleIncludeOnlyMyEvents: {
        defaultMessage: 'Include only my events',
        id: 'export_schedue.include_only_my_events'
    },
    exportScheduleItemTypes: {
        defaultMessage: 'Select the event details you want to include in the exported calendar:',
        id: 'export_schedule.item_types'
    },
    exportScheduleResetToken: {
        defaultMessage: 'Reset Token',
        id: 'export_schedule.reset_token'
    },
    exportScheduleWarning: {
        defaultMessage: 'Anyone who sees the above link will be able to view all event details of this calendar.',
        id: 'export_schedule.warning'
    },
    externalViewers: {
        defaultMessage: 'External Viewers',
        id: 'generic.external_viewers'
    },
    facilitator: {
        defaultMessage: 'Facilitator',
        id: 'facilitator'
    },
    false: {
        defaultMessage: 'False',
        id: 'generic.false'
    },
    fast: {
        defaultMessage: '1.5x',
        id: 'trackbar.settings.fast'
    },
    faster: {
        defaultMessage: '2x',
        id: 'trackbar.settings.faster'
    },
    filter: {
        defaultMessage: 'Filter',
        id: 'generic.filter'
    },
    filterByDate: {
        defaultMessage: 'Filter by Date',
        id: 'generic.filterByDate'
    },
    filters: {
        defaultMessage: 'Filters',
        id: 'generic.filters'
    },
    firefoxNotSupportedDescription: {
        defaultMessage:
            'Firefox is not supported for SimCapture Remote OSCE. Please use the Chrome web browser to gain access to the following exams.',
        id: 'generic.firefox.not_supported.description'
    },
    firstName: {
        defaultMessage: 'First Name',
        id: 'generic.first_name'
    },
    formNoItems: {
        defaultMessage: 'No {itemTypePlural} found.',
        description: 'Message displayed in form components (e.g. comboboxes, dropdowns) when there are no items.',
        id: 'form.no_items'
    },
    formNoUsersFound: {
        defaultMessage: 'No users found.',
        id: 'form.noUsersFound'
    },
    freeText: {
        defaultMessage: 'Free Text',
        id: 'evaluations_download_options_modal.free_text'
    },
    freeTextAnswerOptionPlaceholder: {
        defaultMessage: 'Answer for this question will be filled out here.',
        id: 'questions.free_text_answer_option_placeholder'
    },
    freeTextPlaceholder: {
        defaultMessage: 'Enter Free Text Here',
        id: 'questions.free_text_placeholder'
    },
    fullName: {
        defaultMessage: 'Full Name',
        id: 'generic.full_name'
    },
    gradebook: {
        defaultMessage: 'Gradebook',
        id: 'gradebook'
    },
    green: {
        defaultMessage: 'Green',
        id: 'green'
    },
    help: {
        defaultMessage: 'Help',
        id: 'generic.help'
    },
    hideDetails: {
        defaultMessage: 'Hide details',
        id: 'generic.hideDetails'
    },
    hideFullScreen: {
        defaultMessage: 'Exit full screen',
        id: 'trackbar.fullScreen.hide'
    },
    hint: {
        defaultMessage: 'Add Hint Text',
        id: 'questions.hint'
    },
    hintTextPlaceholder: {
        defaultMessage: 'Enter Hint Text Here',
        id: 'questions.hint_text_placeholder'
    },
    honorCode: {
        defaultMessage: 'Honor Code',
        id: 'honor_code'
    },
    hour: {
        defaultMessage: 'Hour',
        id: 'time.hour'
    },
    hours: {
        defaultMessage: 'Hours',
        id: 'time.hours'
    },
    import: {
        defaultMessage: 'Import',
        id: 'generic.import'
    },
    importItem: {
        defaultMessage: 'Import {itemType}',
        id: 'generic.import_item'
    },
    importing: {
        defaultMessage: 'Importing',
        id: 'global.importing'
    },
    inUse: {
        defaultMessage: 'In Use',
        id: 'generic.in_use'
    },
    inactive: {
        defaultMessage: 'Inactive',
        id: 'generic.inactive'
    },
    inactiveUserWarning: {
        defaultMessage:
            'Your user account has been deactivated. If you think this is in error, please contact your administrator.',
        id: 'signedIn.unauthorized.inactive_user'
    },
    include: {
        defaultMessage: 'Include',
        id: 'evaluations_download_options_modal.include'
    },
    incorrect: {
        defaultMessage: 'Please enter correct original password',
        id: 'password.error.incorrect'
    },
    informationPlaceholder: {
        defaultMessage: 'Enter Information Text Here',
        id: 'questions.information_placeholder'
    },
    insertFreeText: {
        defaultMessage: 'Insert Free Text',
        id: 'questions.insert_free_text'
    },
    insertFromQuestionLibrary: {
        defaultMessage: 'Insert From Question Library',
        id: 'questions.insert_from_question_library'
    },
    insertInstruction: {
        defaultMessage: 'Insert Instructions',
        id: 'questions.insert_instructions'
    },
    insertMultipleChoice: {
        defaultMessage: 'Insert Multiple Choice',
        id: 'questions.insert_multiple_choice'
    },
    insertQuestionTitle: {
        defaultMessage: 'Insert Question',
        id: 'questions.insert_question_title'
    },
    insertRowLabel: {
        defaultMessage: 'Insert row with columns:',
        description: 'Label for insert row popover',
        id: 'emr_template_builder.insert_row_label'
    },
    insertUSMLEDiagnosis: {
        defaultMessage: 'Insert USMLE Diagnosis',
        id: 'questions.insert_usmle_diagnosis'
    },
    instructions: {
        defaultMessage: 'Select options for download:',
        id: 'evaluations_download_options_modal.instructions'
    },
    integrationEventId: {
        defaultMessage: 'Event ID',
        id: 'integrationEventId'
    },
    internalServerErrorBody: {
        defaultMessage: 'Something went wrong. Please try again later.',
        id: 'errors.internal_server.body'
    },
    inventory: {
        defaultMessage: 'Inventory',
        id: 'inventory'
    },
    inventoryItem: {
        defaultMessage: 'Inventory item',
        id: 'generic.inventory_item'
    },
    inventoryItems: {
        defaultMessage: 'Inventory items',
        id: 'generic.inventory_items'
    },
    inventoryQuantityTypePerParticipantLabel: {
        defaultMessage: 'Per Participant',
        id: 'inventory.quantity_type_per_participant_label'
    },
    inventoryQuantityTypePerParticipantString: {
        defaultMessage: 'per participant',
        id: 'inventory.quantity_type_per_participant_string'
    },
    inventoryQuantityTypePerSessionLabel: {
        defaultMessage: 'Per Session',
        id: 'inventory.quantity_type_per_session_label'
    },
    inventoryQuantityTypePerSessionString: {
        defaultMessage: 'per session',
        id: 'inventory.quantity_type_per_session_string'
    },
    inventoryRequired: {
        defaultMessage: 'Inventory Required',
        id: 'reservation_new_form.inventory_required'
    },
    lastActivity: {
        defaultMessage: 'Last Activity',
        id: 'generic.last_activity'
    },
    lastName: {
        defaultMessage: 'Last Name',
        id: 'generic.last_name'
    },
    layout: {
        defaultMessage: 'Layout',
        id: 'trackbar.settings.layout'
    },
    layoutAdjacent: {
        defaultMessage: 'Adjacent',
        id: 'layout.adjacent'
    },
    layoutBelow: {
        defaultMessage: 'Below',
        id: 'layout.below'
    },
    layoutFlow: {
        defaultMessage: 'Flow',
        id: 'trackbar.layout.flow'
    },
    layoutHorizontal: {
        defaultMessage: 'Horizontal',
        id: 'trackbar.layout.horizontal'
    },
    layoutPip: {
        defaultMessage: 'PiP',
        id: 'trackbar.layout.pip'
    },
    layoutQuad: {
        defaultMessage: 'Quad',
        id: 'trackbar.layout.quad'
    },
    layoutVertical: {
        defaultMessage: 'Vertical',
        id: 'trackbar.layout.vertical'
    },
    layout_horiz_adj: {
        defaultMessage: 'Horizontal Adjacent',
        id: 'layout.horiz_adj'
    },
    layout_horiz_below: {
        defaultMessage: 'Horizontal Below',
        id: 'layout.horiz_below'
    },
    layout_one_column: {
        defaultMessage: 'One Column',
        id: 'layout.one_column'
    },
    layout_two_columns: {
        defaultMessage: 'Two Columns',
        id: 'layout.two_columns'
    },
    layout_vert_adj: {
        defaultMessage: 'Vertical Adjacent',
        id: 'layout.vert_adj'
    },
    layout_vert_below: {
        defaultMessage: 'Vertical Below',
        id: 'layout.vert_below'
    },
    learner: {
        defaultMessage: 'Learner',
        id: 'generic.learner'
    },
    learnerDriven: {
        defaultMessage: 'Learner driven',
        id: 'learner_driven'
    },
    learnerEvaluation: {
        defaultMessage: 'Learner Evaluation',
        id: 'learner_evaluation'
    },
    learners: {
        defaultMessage: 'Learners',
        id: 'generic.learners'
    },
    less: {
        defaultMessage: 'less',
        id: 'less'
    },
    library: {
        defaultMessage: 'Library',
        description: 'Label for question library button.',
        id: 'library'
    },
    listNoItems: {
        defaultMessage: 'No {itemTypePlural} found. Please check your filter settings or {addItemLink}.',
        description: 'Message displayed in data list components when there are no items.',
        id: 'list.no_items'
    },
    listNoItemsError: {
        defaultMessage: 'Unable to reach SimCapture server—please refresh or try again later.',
        id: 'list.no_items.error'
    },
    listNoItemsGeneric: {
        defaultMessage: 'No items found.',
        id: 'list.no_items.generic'
    },
    listNoItemsLink: {
        defaultMessage: 'create a new {itemType}',
        id: 'list.no_items.link'
    },
    listNoItemsSearching: {
        defaultMessage: 'Searching...',
        id: 'list.no_items.searching'
    },
    listNoItemsSelected: {
        defaultMessage: 'No {itemTypePlural} selected.',
        id: 'list.no_items.none_selected'
    },
    listNoItemsWithoutLink: {
        defaultMessage: 'No {itemTypePlural} found. Please check your filter settings.',
        description:
            'Message displayed in data list components when there are no items and the user is not authorized to create new items.',
        id: 'list.no_items.without_link'
    },
    listNoUsersSelected: {
        defaultMessage: 'No users selected.',
        id: 'list.noUsersSelected'
    },
    loadMore: {
        defaultMessage: 'Load more',
        id: 'general.loadMore'
    },
    loading: {
        defaultMessage: 'Loading...',
        id: 'generic.loading'
    },
    loadingError: {
        defaultMessage: 'Something went wrong and the page did not load.',
        id: 'loading.error.message'
    },
    locale: {
        defaultMessage: 'Locale',
        id: 'generic.locale'
    },
    locales: {
        defaultMessage: 'Locales',
        id: 'generic.locales'
    },
    location: {
        defaultMessage: 'Location',
        id: 'generic.location'
    },
    locations: {
        defaultMessage: 'Locations',
        id: 'generic.locations'
    },
    locationsList: {
        defaultMessage: 'Locations: {locations}',
        id: 'schedule.event.locations_list'
    },
    logout: {
        defaultMessage: 'Log out',
        id: 'signedIn.unauthorized.logout'
    },
    manage: {
        defaultMessage: 'Manage',
        id: 'generic.manage'
    },
    mapEventsTooltipText: {
        defaultMessage: 'Associate Answers to integration Events',
        id: 'global.mapEventsTooltipText'
    },
    markAsComment: {
        defaultMessage: 'Mark as comment',
        id: 'questions.mark_as_comment'
    },
    markAsCommentTooltipText: {
        defaultMessage: 'Note: Comments can be released separately from the evaluation',
        id: 'questions.mark_as_comment.tooltip_text'
    },
    mediaUploadInProgress: {
        defaultMessage:
            'Your exam recording is being uploaded. Please do not leave this page or close your browser until the upload is complete.',
        id: 'media_upload.in_progress'
    },
    message: {
        defaultMessage: 'Message',
        id: 'generic.message'
    },
    middleName: {
        defaultMessage: 'Middle Name',
        id: 'generic.middle_name'
    },
    minimize: {
        defaultMessage: 'Minimize',
        id: 'trackbar.minimize'
    },
    minutes: {
        defaultMessage: 'Minutes',
        id: 'time.minutes'
    },
    monitor: {
        defaultMessage: 'Monitor',
        id: 'generic.monitor'
    },
    monitors: {
        defaultMessage: 'Monitors',
        id: 'generic.monitors'
    },
    month: {
        defaultMessage: 'Month',
        id: 'time.month'
    },
    months: {
        defaultMessage: 'Months',
        id: 'time.months'
    },
    more: {
        defaultMessage: 'More',
        id: 'generic.more'
    },
    moreDetails: {
        defaultMessage: 'More Details',
        id: 'generic.moreDetails'
    },
    move: {
        defaultMessage: 'Move',
        id: 'generic.move'
    },
    moveQuestionSourceText: {
        defaultMessage: 'Question Selected',
        description: 'Text displayed over the question being moved',
        id: 'emr_template_builder.move_question.source_text'
    },
    moveQuestionTargetText: {
        defaultMessage: 'Click to drop question Here',
        description: 'Text displayed over the placeholder where a question will be dropped',
        id: 'emr_template_builder.move_question.target_text'
    },
    multiline: {
        defaultMessage: 'Multiple Lines Allowed',
        id: 'questions.multiline'
    },
    multipleAnswersAllowed: {
        defaultMessage: 'Multiple Answers Allowed',
        id: 'questions.multiple_answers_allowed'
    },
    multipleChoice: {
        defaultMessage: 'Multiple Choice',
        id: 'evaluations_download_options_modal.multiple_choice'
    },
    multipleChoiceDisplayAsDropdown: {
        defaultMessage: 'Display as dropdown',
        id: 'questions.multiple_choice.display_as_dropdown'
    },
    multipleChoicePlaceholder: {
        defaultMessage: 'Enter Multiple Choice Question Here',
        id: 'questions.multiple_choice_placeholder'
    },
    multipleUsersContinue: {
        defaultMessage: 'Continue as the other user',
        id: 'signedIn.unauthorized.multiple_users.continue'
    },
    multipleUsersLogout: {
        defaultMessage: 'Log out of all tabs',
        id: 'signedIn.unauthorized.multiple_users.logout'
    },
    multipleUsersWarning: {
        defaultMessage: 'Another user has logged into this machine. What would you like to do?',
        id: 'signedIn.unauthorized.multiple_users'
    },
    na: {
        defaultMessage: 'N/A',
        description: 'Abbreviation of not applicable',
        id: 'generic.na'
    },
    name: {
        defaultMessage: 'Name',
        id: 'generic.name'
    },
    networkErrorCheckConnection: {
        defaultMessage: 'Please check your network connection or try again later.',
        id: 'errors.network_error.checkConnection'
    },
    networkErrorTitle: {
        defaultMessage: 'Network Error',
        id: 'errors.network_error.title'
    },
    networkErrorUnavailable: {
        defaultMessage: 'The SimCapture server is currently unavailable.',
        id: 'errors.network_error.unavailable'
    },
    newPassword: {
        defaultMessage: 'New password',
        id: 'password.new'
    },
    newScenarioExamBlockAudioUserSettings: {
        defaultMessage: 'Audio and User Settings',
        id: 'course.new_scenario.audio_user_settings'
    },
    newScenarioExamBlockConfigHelpText: {
        defaultMessage: 'Select your Exam Phases',
        id: 'courses.new_scenario.exam_block_config_help_text'
    },
    next: {
        defaultMessage: 'Next',
        id: 'generic.next'
    },
    nextPage: {
        defaultMessage: 'Next page',
        id: 'pagination.next'
    },
    nightingaleLink: {
        defaultMessage: 'SimCapture for Skills interface',
        id: 'nightingale_link'
    },
    no: {
        defaultMessage: 'No',
        id: 'generic.no'
    },
    noAdditionalResults: {
        defaultMessage: 'No additional results',
        id: 'noAdditionalResults'
    },
    noContentReleasedForEvaluation: {
        defaultMessage: 'No Content has been released for this evaluation.',
        id: 'noContentReleasedForEvaluation'
    },
    noContentReleasedForScoringEvaluation: {
        defaultMessage: 'No Content has been released for this scoring evaluation.',
        id: 'noContentReleasedForScoringEvaluation'
    },
    noItemsFound: {
        defaultMessage: 'No {item}s found',
        description: 'The search did not find any matching items.',
        id: 'generic.no_items_found'
    },
    noLearnerEvaluations: {
        defaultMessage: 'No Learner Evaluations',
        id: 'generic.no_learner_evaluations'
    },
    noLocation: {
        defaultMessage: 'No Location',
        id: 'generic.noLocation'
    },
    noMatch: {
        defaultMessage: 'Passwords do not match',
        id: 'password.error.noMatch'
    },
    noMatchingResults: {
        defaultMessage: 'No matching results',
        description: 'The search did not find any matching results.',
        id: 'noResultsFound'
    },
    noParticipants: {
        defaultMessage: 'This reservation has no participants to copy.',
        id: 'reservation_copy_modal.no_participants'
    },
    noParticipantsList: {
        defaultMessage: 'No Participants',
        id: 'generic.no_participants'
    },
    noRecentActivity: {
        defaultMessage: 'No recent activity to display',
        description: 'Item has no recent activity',
        id: 'recent_activity.no_activity'
    },
    noSearchEntered: {
        defaultMessage: 'Type 3 or more characters to start searching',
        description: 'The user has not typed enough text (3 characters) to trigger a search.',
        id: 'generic.no_search_entered'
    },
    noSimulator: {
        defaultMessage: 'No Simulator',
        id: 'generic.no_simulator'
    },
    noSupportStaff: {
        defaultMessage: 'This reservation has no support staff/faculty to copy.',
        id: 'reservation_copy_modal.no_support_staff'
    },
    noSupportStaffList: {
        defaultMessage: 'No Support Staff/Faculty',
        id: 'generic.no_support_staff'
    },
    noTabs: {
        defaultMessage: 'This EMR is empty.',
        id: 'emr_template_builder.no_tabs'
    },
    noUsersFound: {
        defaultMessage: 'No users found',
        description: 'The search did not find any matching users.',
        id: 'notifications.send.search.no_users_found'
    },
    none: {
        defaultMessage: 'None',
        description: 'Return value of NONE in case the field is blank',
        id: 'generic.none'
    },
    noneCourses: {
        defaultMessage: 'None',
        description: 'Return value of none in case where there is no course, or course field is blank',
        id: 'generic.none_courses'
    },
    normal: {
        defaultMessage: '1x',
        id: 'trackbar.settings.normal'
    },
    not: {
        defaultMessage: 'NOT',
        id: 'generic.not'
    },
    notAllowedWarning: {
        defaultMessage:
            'You are not allowed to perform this task. If you think this is in error, please contact your administrator.',
        id: 'signedIn.unauthorized.warning'
    },
    notFoundErrorBody: {
        defaultMessage: 'The server could not find the requested resource.',
        id: 'errors.not_found.body'
    },
    notPassingScore: {
        defaultMessage: 'Not a passing score',
        id: 'generic.not_passing_score'
    },
    notRecording: {
        defaultMessage: 'Not recording',
        id: 'not_recording'
    },
    notes: {
        defaultMessage: 'Notes',
        id: 'generic.notes'
    },
    notification: {
        defaultMessage: 'Notification',
        id: 'generic.notification'
    },
    notifications: {
        defaultMessage: 'Notifications',
        id: 'generic.notifications'
    },
    notificationsSend: {
        defaultMessage: 'Send Notification',
        id: 'global.notificationsSend'
    },
    notificationsSendTo: {
        defaultMessage: 'To',
        description: 'Label for input where notification recipients are selected',
        id: 'global.notificationsSendTo'
    },
    numItemsSelected: {
        defaultMessage: '{count} items selected',
        description: 'Informational text for multiple selection inputs that do not show the selected items themselves',
        id: 'global.numItemsSelected'
    },
    numberPercent: {
        defaultMessage: '{value}%',
        id: 'number.percent'
    },
    observers: {
        defaultMessage: 'Observers',
        id: 'generic.observers'
    },
    offline: {
        defaultMessage: 'Offline',
        id: 'generic.offline'
    },
    offset: {
        defaultMessage: 'Offset',
        id: 'generic.offset'
    },
    okay: {
        defaultMessage: 'Okay',
        id: 'okay'
    },
    oldMatch: {
        defaultMessage: 'New Password cannot match old password',
        id: 'password.error.oldMatch'
    },
    oldPassword: {
        defaultMessage: 'Old password',
        id: 'password.old'
    },
    oldPasswordHelp: {
        defaultMessage: 'Enter your original password',
        id: 'password.old.help'
    },
    onYourList: {
        defaultMessage: 'On your list',
        id: 'generic.onYourList'
    },
    open: {
        defaultMessage: 'Open',
        id: 'global.open'
    },
    or: {
        defaultMessage: 'or',
        id: 'generic.or'
    },
    order: {
        defaultMessage: 'Order',
        id: 'generic.order'
    },
    organization: {
        defaultMessage: 'Organization',
        id: 'generic.organization'
    },
    organizationArchived: {
        defaultMessage: '{organization} (Archived)',
        id: 'generic.organizationArchived'
    },
    organizationHelpText: {
        defaultMessage: 'Which organization does this reservation belong to?',
        id: 'reservation_new_form.organization.help_text'
    },
    organizationPlaceholder: {
        defaultMessage: 'Search for Organization',
        id: 'generic.organization.placeholder'
    },
    organizations: {
        defaultMessage: 'Organizations',
        id: 'generic.organizations'
    },
    overview: {
        defaultMessage: 'Overview',
        id: 'generic.overview'
    },
    owner: {
        defaultMessage: 'Owner',
        id: 'generic.owner'
    },
    owners: {
        defaultMessage: 'Owners',
        id: 'generic.owners'
    },
    pageNotFound: {
        defaultMessage: 'The page you are looking for cannot be found.',
        id: 'page_not_found'
    },
    pageNotFoundCheckUrl: {
        defaultMessage: 'Please check your URL to make sure it is correct.',
        id: 'page_not_found.check_url'
    },
    pageNotFoundReturnToDashboard: {
        defaultMessage: 'Return to dashboard',
        id: 'page_not_found.return_to_dashboard'
    },
    parentEvaluationLabel: {
        defaultMessage: 'Evaluation: {evaluationTemplateTitle}',
        id: 'global.evaluation.scoring.edit.parent_evaluation_label'
    },
    participant: {
        defaultMessage: 'Participant',
        id: 'generic.participant'
    },
    participantResultsCourseLabelAfterSession: {
        defaultMessage: 'After Session',
        id: 'participant_results.course.label.after_session'
    },
    participantResultsCourseLabelBeforeSession: {
        defaultMessage: 'Before Session',
        id: 'participant_results.course.label.before_session'
    },
    participantResultsCourseLabelCourse: {
        defaultMessage: 'Course',
        id: 'participant_results.course.label.course'
    },
    participantResultsCourseLabelDuringSession: {
        defaultMessage: 'Administrator',
        id: 'participant_results.course.label.during_session'
    },
    participantResultsCourseLabelEvaluationsLegend: {
        defaultMessage: 'Evaluations Legend',
        id: 'participant_results.course.label.evaluations_legend'
    },
    participantResultsCourseLabelManualScenario: {
        defaultMessage: 'Standalone Scenario',
        id: 'participant_results.course.label.manual_scenario'
    },
    participantResultsCourseLabelP2P: {
        defaultMessage: 'Peer-to-peer',
        id: 'participant_results.course.label.p2p'
    },
    participantResultsCourseLabelTotalScore: {
        defaultMessage: 'Total Score',
        id: 'participant_results.course.label.total_score'
    },
    participants: {
        defaultMessage: 'Participants',
        id: 'generic.participants'
    },
    participantsCourse: {
        defaultMessage: 'Course participants ({count})',
        id: 'generic.courseParticipantsWithCount'
    },
    participantsList: {
        defaultMessage: 'Participants: {participant}',
        id: 'schedule.event.participants_list'
    },
    participantsListMultiple: {
        defaultMessage: 'Participants: {firstParticipant}, {count} more...',
        id: 'schedule.event.participants_list_multiple'
    },
    participantsSelfEnroll: {
        defaultMessage: 'Participants (self enrolled)',
        id: 'generic.participants.selfenrolled'
    },
    passingCutoff: {
        defaultMessage: 'Passing cutoff',
        id: 'passing_cutoff'
    },
    passingCutoffCutoffType: {
        defaultMessage: 'Cutoff type',
        id: 'passing_cutoff.cutoff_type'
    },
    passingCutoffErrorAbsolutePercentage: {
        defaultMessage: 'Must be between 0 and 100',
        id: 'passing_cutoff.error.absolute_percentage'
    },
    passingCutoffErrorStandardDeviation: {
        defaultMessage: 'Must be between 0 and 3',
        id: 'passing_cutoff.error.standard_deviation'
    },
    passingCutoffLabelAbsolutePercent: {
        defaultMessage: 'Absolute percent',
        id: 'passing_cutoff.label.absolute_percent'
    },
    passingCutoffLabelOverall: {
        defaultMessage: 'Overall',
        id: 'passing_cutoff.label.overall'
    },
    passingCutoffLabelPassFailCutoffPercent: {
        defaultMessage: 'Pass/fail cutoff (%)',
        id: 'passing_cutoff.label.pass_fail_cutoff_percent'
    },
    passingCutoffLabelPassFailCutoffStd: {
        defaultMessage: 'Pass/fail cutoff (# of STDEV)',
        id: 'passing_cutoff.label.pass_fail_cutoff_std'
    },
    passingCutoffLabelStandardDeviationsBelowMean: {
        defaultMessage: 'Standard deviations below the mean',
        id: 'passing_cutoff.label.standard_deviations_below_mean'
    },
    passingCutoffStandardDeviationsBelowMean: {
        defaultMessage:
            '{standardDeviation} {standardDeviation, plural, one {standard deviation} other {standard deviations}} below the mean',
        id: 'passing_cutoff.standard_deviations_below_mean'
    },
    passingScore: {
        defaultMessage: 'Passing score',
        id: 'generic.passing_score'
    },
    password: {
        defaultMessage: 'Password',
        id: 'generic.password'
    },
    passwordExpired: {
        defaultMessage: 'Your password has expired.',
        id: 'warning.passwordExpired'
    },
    passwordExpiredLink: {
        defaultMessage: 'Change password',
        id: 'warning.passwordExpired.link'
    },
    passwordHelpMedium: {
        defaultMessage:
            'Passwords must contain at least one capitalized letter, one lowercase letter, and one number (mimimum 8 characters)',
        id: 'password.new.help.medium'
    },
    passwordHelpStrong: {
        defaultMessage:
            'Passwords must contain at least one capitalized letter, one lowercase letter, one number, and one special character (minimum 10 characters)',
        id: 'password.new.help.strong'
    },
    passwordHelpWeak: {
        defaultMessage: 'Passwords must be at least one character long.',
        id: 'password.new.help.weak'
    },
    paste: {
        defaultMessage: 'Paste',
        id: 'generic.paste'
    },
    patient: {
        defaultMessage: 'Patient',
        id: 'generic.patient'
    },
    patientEvaluation: {
        defaultMessage: 'Patient Evaluation',
        id: 'patient_evaluation'
    },
    patients: {
        defaultMessage: 'Patients',
        id: 'generic.patients'
    },
    peerToPeer: {
        defaultMessage: 'Peer to Peer',
        id: 'peerToPeer'
    },
    people: {
        defaultMessage: 'People',
        id: 'generic.people'
    },
    performance: {
        defaultMessage: 'Performance',
        id: 'global.performance'
    },
    permissionOff: {
        defaultMessage: 'NO',
        description: 'set permission to false',
        id: 'permissions.off'
    },
    permissionOn: {
        defaultMessage: 'YES',
        description: 'set permission to true',
        id: 'permissions.on'
    },
    pleaseReload: {
        defaultMessage: 'Please reload.',
        id: 'generic.pleaseReload'
    },
    plusMore: {
        defaultMessage: '+ {count} more',
        id: 'generic.plus-more'
    },
    postEncounter: {
        defaultMessage: 'Post Encounter',
        id: 'global.postEncounter'
    },
    preview: {
        defaultMessage: 'Preview',
        id: 'generic.preview'
    },
    previousPage: {
        defaultMessage: 'Previous page',
        id: 'pagination.previous'
    },
    print: {
        defaultMessage: 'Print',
        id: 'generic.print'
    },
    printFormTemplate: {
        defaultMessage: 'Print Evaluation Template',
        id: 'form_template.print'
    },
    printFormTemplateDescription: {
        defaultMessage:
            'A PDF file is being generated for this evaluation template. The download will start when it is ready.',
        id: 'form_template.print.description'
    },
    printFormTemplateSelectOptions: {
        defaultMessage: 'Select print options:',
        id: 'form_template.print.select_options'
    },
    printFormTemplateShowAdminOptions: {
        defaultMessage: 'Show evaluator and private titles',
        id: 'form_template.print.show_admin_options'
    },
    printFormTemplateShowCommentQuestions: {
        defaultMessage: 'Show questions marked as comments',
        id: 'form_template.print.show_comment_questions'
    },
    printFormTemplateShowPercentageValues: {
        defaultMessage: 'Show percentage values',
        id: 'form_template.print.show_percentage_values'
    },
    printFormTemplateShowPointValues: {
        defaultMessage: 'Show point values',
        id: 'form_template.print.show_point_values'
    },
    printFormTemplateShowQuestionCategories: {
        defaultMessage: 'Show question categories',
        id: 'form_template.print.show_question_categories'
    },
    printFormTemplateSplitByParticipant: {
        defaultMessage: 'Split evaluation by participant',
        id: 'form_template.print.split_by_participant'
    },
    printScoringRubric: {
        defaultMessage: 'Print Scoring Rubric',
        id: 'form_template.print.scoring_rubric'
    },
    privateNotes: {
        defaultMessage: 'Private Notes',
        id: 'reservation_new_form.private_notes'
    },
    privateNotesHelpText: {
        defaultMessage: 'Notes that only other administrators can see.',
        id: 'reservation_new_form.private_notes.help_text'
    },
    proceed: {
        defaultMessage: 'Proceed',
        id: 'generic.proceed'
    },
    proceedToDelete: {
        defaultMessage: 'Proceed to delete',
        id: 'generic.proceed_to_delete'
    },
    profile: {
        defaultMessage: 'Profile',
        id: 'generic.profile'
    },
    publicNotes: {
        defaultMessage: 'Public Notes',
        id: 'reservation_new_form.public_notes'
    },
    publicNotesHelpText: {
        defaultMessage: 'Notes that everyone can see.',
        id: 'reservation_new_form.public_notes.help_text'
    },
    question: {
        defaultMessage: 'question',
        id: 'generic.question'
    },
    questionAddAnswerOption: {
        defaultMessage: 'Add answer option',
        id: 'emr_template_builder.question.add_answer_option'
    },
    questionAllowMultipleAnswers: {
        defaultMessage: 'Allow multiple answers',
        id: 'emr_template_builder.question.allow_multiple_answers'
    },
    questionAnswerLimit: {
        defaultMessage: 'Answer limit',
        id: 'emr_template_builder.question.answer_limit'
    },
    questionCategories: {
        defaultMessage: 'Question Categories',
        id: 'question_categories'
    },
    questionCategory: {
        defaultMessage: 'Question Category',
        id: 'questions.question_category'
    },
    questionCategoryPlaceholder: {
        defaultMessage: 'Select a Question Category',
        id: 'questions.question_category_placeholder'
    },
    questionCharacterLimit: {
        defaultMessage: 'Character limit',
        id: 'emr_template_builder.question.character_limit'
    },
    questionDisplayAsDropdown: {
        defaultMessage: 'Display as dropdown',
        id: 'emr_template_builder.question.display_as_dropdown'
    },
    questionFreeTextAnswerPlaceholder: {
        defaultMessage: 'Answer for this question will be filled out here.',
        id: 'emr_template_builder.question_free_text_answer_placeholder'
    },
    questionHintTextEnable: {
        defaultMessage: 'Add hint text',
        id: 'emr_template_builder.question.hint_text.enable'
    },
    questionHintTextPlaceholder: {
        defaultMessage: 'Enter hint text',
        id: 'emr_template_builder.question.hint_text.placeholder'
    },
    questionMultiline: {
        defaultMessage: 'Multiline',
        id: 'emr_template_builder.question.multiline'
    },
    questionRandomizeAnswerOrder: {
        defaultMessage: 'Randomize answer order',
        id: 'emr_template_builder.question.randomize_answer_order'
    },
    questionTextPlaceholder: {
        defaultMessage: 'Enter question text here',
        id: 'emr_template_builder.question_text_placeholder'
    },
    questionTypeFreeText: {
        defaultMessage: 'Free Text',
        id: 'question_type.free_text'
    },
    questionTypeInformation: {
        defaultMessage: 'Information',
        id: 'question_type.information'
    },
    questionTypeMultiline: {
        defaultMessage: 'Multiline',
        id: 'question_type.multiline'
    },
    questionTypeMultipleChoice: {
        defaultMessage: 'Multiple Choice',
        id: 'question_type.multiple_choice'
    },
    questionTypeSlider: {
        defaultMessage: 'Slider',
        id: 'question_type.slider'
    },
    questionTypeUSMLEDiagnosis: {
        defaultMessage: 'USMLE Diagnosis',
        id: 'question_type.usmle_diagnosis'
    },
    questionUSMLEDiagnosisAnswerPlaceholder: {
        defaultMessage: 'Answer will be filled out here.',
        id: 'emr_template_builder.question.usmle_diagnosis.answer_placeholder'
    },
    questionUSMLEDiagnosisDiagnosisLabel: {
        defaultMessage: 'Diagnosis',
        description: 'To be used until we make USMLE question section labels editable.',
        id: 'emr_template_builder.question.usmle_diagnosis.builder.diagnosis_label'
    },
    questionUSMLEDiagnosisHistoryLabel: {
        defaultMessage: 'History Finding(s)',
        description: 'To be used until we make USMLE question section labels editable.',
        id: 'emr_template_builder.question.usmle_diagnosis.builder.history_label'
    },
    questionUSMLEDiagnosisPhysicalExamLabel: {
        defaultMessage: 'Physical Exam Finding(s)',
        description: 'To be used until we make USMLE question section labels editable.',
        id: 'emr_template_builder.question.usmle_diagnosis.builder.physical_exam_label'
    },
    quickAdd: {
        defaultMessage: 'Quick add',
        id: 'generic.quickAdd'
    },
    randomizeAnswers: {
        defaultMessage: 'Randomize Answer Order',
        id: 'questions.randomize_answers'
    },
    recentActivity: {
        defaultMessage: 'Recent Activity',
        id: 'generic.recent_activity'
    },
    recentActivityLoadingError: {
        defaultMessage: 'Unable to load recent activity.',
        id: 'recent_activity.loading_error'
    },
    reconnect: {
        defaultMessage: 'Reconnect',
        id: 'reconnect'
    },
    recording: {
        defaultMessage: 'Recording',
        id: 'recording'
    },
    recordingOff: {
        defaultMessage: 'Recording is OFF',
        id: 'recording_off'
    },
    recordingOn: {
        defaultMessage: 'Recording is ON',
        id: 'recording_on'
    },
    red: {
        defaultMessage: 'Red',
        id: 'red'
    },
    reflection: {
        defaultMessage: 'Reflection',
        id: 'generic.reflection'
    },
    reflections: {
        defaultMessage: 'Reflections',
        id: 'generic.reflections'
    },
    refresh: {
        defaultMessage: 'Refresh',
        id: 'generic.refresh'
    },
    reload: {
        defaultMessage: 'Reload',
        id: 'generic.reload'
    },
    reloadPage: {
        defaultMessage: 'Reload Page',
        id: 'reload.page'
    },
    remoteExam: {
        defaultMessage: 'Remote Exam',
        id: 'remote-exam'
    },
    remoteExamLearnerLink: {
        defaultMessage: 'Remote Exam: Learner',
        id: 'remoteExamLearnerLink'
    },
    remoteExamPatientLink: {
        defaultMessage: 'Remote Exam: Patient',
        id: 'remoteExamPatientLink'
    },
    remoteExamRoomWithNum: {
        defaultMessage: 'Remote Room {roomNumber}',
        id: 'global.remoteExamRoomWithNum'
    },
    remove: {
        defaultMessage: 'Remove',
        id: 'generic.remove'
    },
    removeAll: {
        defaultMessage: 'Remove All',
        id: 'generic.removeAll'
    },
    removeRow: {
        defaultMessage: 'Remove row',
        id: 'emr_template_builder.remove_row'
    },
    rename: {
        defaultMessage: 'Rename',
        id: 'generic.rename'
    },
    renameTab: {
        defaultMessage: 'Rename tab',
        id: 'emr_template_builder.rename_tab'
    },
    reorder: {
        defaultMessage: 'Reorder',
        id: 'generic.reorder'
    },
    reorderEndOption: {
        defaultMessage: '(END)',
        id: 'reorder_modal.end_option'
    },
    reorderItemBefore: {
        defaultMessage: 'Move the {itemType} before:',
        id: 'reorder_modal.reorder_item_before'
    },
    reorderModalTitle: {
        defaultMessage: 'Reorder {itemType}',
        id: 'reorder_modal.title'
    },
    replay10: {
        defaultMessage: 'Rewind 10 seconds',
        id: 'trackbar.replay10'
    },
    report: {
        defaultMessage: 'Report',
        id: 'generic.report'
    },
    reportOn: {
        defaultMessage: 'Report on',
        id: 'generic.report_on'
    },
    reports: {
        defaultMessage: 'Reports',
        id: 'generic.reports'
    },
    required: {
        defaultMessage: 'Required',
        id: 'required'
    },
    reservation: {
        defaultMessage: 'Reservation',
        id: 'reservation_modal.reservation'
    },
    reservationLocationConflictPlural: {
        defaultMessage: 'The following locations are busy during the selected time: ',
        id: 'reservationLocationConflictPlural'
    },
    reservationLocationConflictSingular: {
        defaultMessage: 'The following location is busy during the selected time: ',
        id: 'reservationLocationConflictSingular'
    },
    reservationParticipantConflictPlural: {
        defaultMessage: 'The following participants are busy during the selected time: ',
        id: 'reservationParticipantConflictPlural'
    },
    reservationParticipantConflictSingular: {
        defaultMessage: 'The following participant is busy during the selected time: ',
        id: 'reservationParticipantConflictSingular'
    },
    reservationSimulatorConflictPlural: {
        defaultMessage: 'The following simulators are busy during the selected time: ',
        id: 'reservationSimulatorConflictPlural'
    },
    reservationSimulatorConflictSingular: {
        defaultMessage: 'The following simulator is busy during the selected time: ',
        id: 'reservationSimulatorConflictSingular'
    },
    reset: {
        defaultMessage: 'Reset',
        id: 'generic.reset'
    },
    results: {
        defaultMessage: 'Results',
        id: 'generic.results'
    },
    returnTo: {
        defaultMessage: 'Return to {view}',
        id: 'generic.return_to'
    },
    returnToDashboard: {
        defaultMessage: 'Return to dashboard',
        id: 'generic.return_to_dashboard'
    },
    room: {
        defaultMessage: 'Room',
        id: 'room'
    },
    rooms: {
        defaultMessage: 'Rooms',
        id: 'rooms'
    },
    rotate: {
        defaultMessage: 'Rotate',
        id: 'generic.rotate'
    },
    rotationalEvent: {
        defaultMessage: 'Rotational Event',
        id: 'rotational_event'
    },
    rowsPerPage: {
        defaultMessage: 'Rows per page:',
        id: 'pagination.rowsPerPage'
    },
    save: {
        defaultMessage: 'Save',
        id: 'generic.save'
    },
    saveAndNext: {
        defaultMessage: 'Save and next',
        id: 'generic.save_and_next'
    },
    saveError: {
        defaultMessage: 'Unable to save changes',
        id: 'generic.save_error'
    },
    scenario: {
        defaultMessage: 'Scenario',
        id: 'generic.scenario'
    },
    scenarioDisabledPlaceholder: {
        defaultMessage: 'Select course to select scenario',
        id: 'scenario_input.disabled.placeholder'
    },
    scenarioHelpText: {
        defaultMessage: 'Scenario for this Reservation',
        id: 'reservation_new_form.scenario.help_text'
    },
    scenarioPlaceholder: {
        defaultMessage: 'Select Scenario',
        id: 'scenario_input.placeholder'
    },
    scenarioTitle: {
        defaultMessage: 'Scenario: {scenarioTitle}',
        id: 'generic.scenarioTitle'
    },
    scenarioTypeRequirementDescription: {
        defaultMessage: 'This option is required for this Scenario type.',
        id: 'scenario_type_requirement.description'
    },
    scenarioTypeStudentDrivenRequirementDescription: {
        defaultMessage: 'Student Driven Encounter disabled by Scenario type selection.',
        id: 'scenario_type_requirement.student_driven.description'
    },
    scenarios: {
        defaultMessage: 'Scenarios',
        id: 'generic.scenarios'
    },
    scoringEvaluationLabel: {
        defaultMessage: 'Scoring Evaluation for "{evaluationTemplateTitle}"',
        id: 'global.evaluation.scoring.edit.scoring_evaluation_label'
    },
    scoringRubric: {
        defaultMessage: 'Scoring Rubric',
        id: 'scoring_rubric'
    },
    scoringRubricTitle: {
        defaultMessage: '{targetEvaluationTemplateTitle} | Scoring Rubric',
        id: 'scoring_rubric_title'
    },
    scoringRubrics: {
        defaultMessage: 'Scoring Rubrics',
        id: 'scoring_rubrics'
    },
    search: {
        defaultMessage: 'Search',
        id: 'generic.search'
    },
    searchBreadcrumbsCourse: {
        defaultMessage: 'Course: {courseTitle}',
        id: 'search.breadcrumbs.course'
    },
    searchBreadcrumbsCourseEvaluation: {
        defaultMessage: 'Course: {courseTitle} | Evaluation: {evaluationTitle}',
        id: 'search.breadcrumbs.course.evalution'
    },
    searchBreadcrumbsCourseScenario: {
        defaultMessage: 'Course: {courseTitle} | Scenario: {scenarioTitle}',
        id: 'search.breadcrumbs.scenario'
    },
    searchBreadcrumbsCourseScenarioEvaluation: {
        defaultMessage: 'Course: {courseTitle} | Scenario: {scenarioTitle} | Evaluation: {evaluationTitle}',
        id: 'search.breadcrumbs.evalution'
    },
    searchInventoryItems: {
        defaultMessage: 'Search Inventory',
        id: 'reservation_new_form.search_inventory_items'
    },
    searchLocation: {
        defaultMessage: 'Search for a Location',
        id: 'reservation_new_form.search_location'
    },
    searchOwnerPlaceholder: {
        defaultMessage: 'Search owners',
        id: 'search.placeholder.owner'
    },
    searchRecipientsPlaceholder: {
        defaultMessage: 'Search to add a recipient',
        id: 'notifications.send.search.placeholder'
    },
    searchResults: {
        defaultMessage: 'Search Results ({numResults})',
        id: 'search.results.header'
    },
    searchResultsLoading: {
        defaultMessage: 'Search Results Loading...',
        id: 'search.results.header.loading'
    },
    searchTerm: {
        defaultMessage: 'Searched term "{term}"',
        id: 'search.results.term'
    },
    searching: {
        defaultMessage: 'Searching...',
        description: 'A search is in progress.',
        id: 'generic.searching'
    },
    seconds: {
        defaultMessage: 'Seconds',
        id: 'seconds'
    },
    select: {
        defaultMessage: 'Select',
        id: 'generic.select'
    },
    selectACourse: {
        defaultMessage: 'Select a course',
        id: 'course.selection'
    },
    selectAll: {
        defaultMessage: 'Select All',
        id: 'data_toggler.select_all'
    },
    selectHonorCode: {
        defaultMessage: 'Select Honor Code',
        id: 'examFlow.selectHonorCode'
    },
    selfRecord: {
        defaultMessage: 'Self Record',
        id: 'selfRecord'
    },
    send: {
        defaultMessage: 'Send',
        id: 'generic.send'
    },
    session: {
        defaultMessage: 'Session',
        id: 'generic.session'
    },
    sessionStarted: {
        defaultMessage:
            'A session has already started in this room. Please contact your administrator to join the recording.',
        id: 'signedIn.unauthorized.session_started'
    },
    sessionTimedOut: {
        defaultMessage: 'Session Timed Out. Please login again.',
        id: 'global.session_timed_out'
    },
    sessionViewer: {
        defaultMessage: 'Session Viewer',
        id: 'generic.sessionViewer'
    },
    sessions: {
        defaultMessage: 'Sessions',
        id: 'generic.sessions'
    },
    settings: {
        defaultMessage: 'Settings',
        id: 'generic.settings'
    },
    setup: {
        defaultMessage: 'setup',
        id: 'schedule.room_setup.setup'
    },
    setupInstructions: {
        defaultMessage: 'Room Setup Instructions',
        id: 'reservation_new_form.setup_instructions'
    },
    setupTime: {
        defaultMessage: 'Setup Time',
        id: 'reservation_new_form.setup_time'
    },
    share: {
        defaultMessage: 'Share',
        id: 'generic.share'
    },
    sharingSettings: {
        defaultMessage: 'Sharing Settings',
        id: 'generic.sharing_settings'
    },
    showAdditionalText: {
        defaultMessage: 'Text',
        id: 'questions.show_additional_text'
    },
    showAdditionalTextToolTip: {
        defaultMessage: 'Add text area to be filled by end user',
        id: 'questions.show_additional_text_tooltip'
    },
    showAdditionalTextToolTipPdl: {
        defaultMessage: 'Prompt user completing checklist to provide additional information or context',
        id: 'questions.show_additional_text_tooltip.pdl'
    },
    showAll: {
        defaultMessage: 'Show all',
        id: 'generic.show_all'
    },
    showAsNumber: {
        defaultMessage: 'Show as number',
        id: 'evaluations_download_options_modal.show_as_number'
    },
    showAsText: {
        defaultMessage: 'Show as text',
        id: 'evaluations_download_options_modal.show_as_text'
    },
    showFullScreen: {
        defaultMessage: 'Full screen',
        id: 'trackbar.fullScreen.show'
    },
    showOnlyActiveCourses: {
        defaultMessage: 'Show Only Active Courses',
        id: 'show_only.courses.active'
    },
    showOnlyActiveScenarios: {
        defaultMessage: 'Show Only Active Scenarios',
        id: 'show_only.scenarios.active'
    },
    showOnlyActiveUsers: {
        defaultMessage: 'Show Only Active Users',
        id: 'show_only.users.active'
    },
    showOnlyMyCourses: {
        defaultMessage: 'Show Only My Courses',
        id: 'show_only.courses.mine'
    },
    showOnlyMyScenarios: {
        defaultMessage: 'Show Only My Scenarios',
        id: 'show_only.scenarios.mine'
    },
    showOnlyMySchedule: {
        defaultMessage: 'Show Only My Schedule',
        id: 'show_only.schedule.mine'
    },
    showOnlyMySessions: {
        defaultMessage: 'Show Only My Sessions',
        id: 'show_only.sessions.mine'
    },
    showOnlySelectedCourses: {
        defaultMessage: 'Show Only Selected Courses',
        id: 'show_only.courses.selected'
    },
    showOnlySelectedScenarios: {
        defaultMessage: 'Show Only Selected Scenarios',
        id: 'show_only.scenarios.selected'
    },
    showOnlySelectedSessions: {
        defaultMessage: 'Show Only Selected Sessions',
        id: 'show_only.sessions.selected'
    },
    showOnlySelectedUsers: {
        defaultMessage: 'Show Only Selected Users',
        id: 'show_only.users.selected'
    },
    signOut: {
        defaultMessage: 'Log Out',
        id: 'sign_out_button.label'
    },
    simBarTitleDefault: {
        defaultMessage: 'Simulator Data',
        id: 'sessions.simulators.sim_bar_title.default'
    },
    simBarTitleNoModel: {
        defaultMessage: 'Simulator Data for {name}',
        id: 'sessions.simulators.sim_bar_title.no_model'
    },
    simBarTitleWithModel: {
        defaultMessage: '{name} - {model}',
        id: 'sessions.simulators.sim_bar_title.with_model'
    },
    simCaptureTitle: {
        defaultMessage: 'SimCapture',
        id: 'simcapture_title'
    },
    simDataBlsResults: {
        defaultMessage: 'BLS Results',
        id: 'sessions.simulators.sim_graph.bls_results'
    },
    simGraphNoData: {
        defaultMessage: 'Simulator stats are unavailable for this session.',
        id: 'sessions.simulators.sim_graph.no_data'
    },
    simGraphToggleSimulatorData: {
        defaultMessage: 'Toggle simulator data',
        id: 'sessions.simGraphToggleSimulatorData'
    },
    simulator: {
        defaultMessage: 'Simulator',
        id: 'generic.simulator'
    },
    simulatorHelpText: {
        defaultMessage: 'Which simulator does this reservation use?',
        id: 'reservation_new_form.simulator.help_text'
    },
    simulators: {
        defaultMessage: 'Simulators',
        id: 'generic.simulators'
    },
    simulatorsList: {
        defaultMessage: 'Simulators: {simulators}',
        id: 'schedule.event.simulators_list'
    },
    singleSignOn: {
        defaultMessage: 'Single Sign-On',
        id: 'single_sign_on'
    },
    speed: {
        defaultMessage: 'Speed',
        id: 'trackbar.settings.speed'
    },
    standardPatient: {
        defaultMessage: 'Standard Patient',
        id: 'generic.standard_patient'
    },
    startDate: {
        defaultMessage: 'Start Date',
        id: 'generic.start_date'
    },
    startTime: {
        defaultMessage: 'Start Time',
        id: 'generic.start_time'
    },
    startTimeAndDuration: {
        defaultMessage: 'Start Time / Duration',
        id: 'generic.start_time_and_duration'
    },
    startedAtDateTime: {
        defaultMessage: 'Started {dateTime}',
        id: 'startedAtDateTime'
    },
    status: {
        defaultMessage: 'Status',
        id: 'generic.status'
    },
    studentDriven: {
        defaultMessage: 'Student Driven',
        id: 'student_driven'
    },
    studentEvaluation: {
        defaultMessage: 'Student Evaluation',
        id: 'student_evaluation'
    },
    subject: {
        defaultMessage: 'Subject',
        id: 'notifications.send.subject'
    },
    sublocation: {
        defaultMessage: 'Sub-Location',
        id: 'generic.sublocation'
    },
    submitLabel: {
        defaultMessage: 'Submit',
        id: 'submit_button.label'
    },
    summary: {
        defaultMessage: 'Summary',
        id: 'generic.summary'
    },
    support: {
        defaultMessage: 'Support',
        id: 'support'
    },
    supportStaff: {
        defaultMessage: 'Support Staff/Faculty',
        id: 'generic.supportStaff'
    },
    supportStaffCourse: {
        defaultMessage: 'Course support staff/faculty ({count})',
        id: 'generic.courseSupportStaffWithCount'
    },
    supportStaffList: {
        defaultMessage: 'Support Staff/Faculty: {supportStaff}',
        id: 'schedule.event.supportStaffList'
    },
    supportStaffListMultiple: {
        defaultMessage: 'Support Staff/Faculty: {firstSupportStaff}, {count} more...',
        id: 'schedule.event.supportStaffListMultiple'
    },
    switchClient: {
        defaultMessage: 'Switch client',
        id: 'generic.switch_client'
    },
    systemAutomatedScenarioConfigureModalTooltip: {
        defaultMessage: 'Scenario type not allowed for remote exams.',
        id: 'system_automated_scenario_configure_modal.tooltip'
    },
    systemSetup: {
        defaultMessage: 'System Setup',
        id: 'generic.system_setup'
    },
    system_admin: {
        defaultMessage: 'System Admin',
        id: 'generic.system_admin'
    },
    takedown: {
        defaultMessage: 'takedown',
        id: 'schedule.room_setup.takedown'
    },
    takedownTime: {
        defaultMessage: 'Takedown Time',
        id: 'reservation_new_form.takedown_time'
    },
    teamEvaluatee: {
        defaultMessage: 'Team',
        id: 'global.evaluation.team'
    },
    text: {
        defaultMessage: 'Text',
        id: 'generic.text'
    },
    thisFieldIsRequired: {
        defaultMessage: 'This field is required.',
        id: 'global.thisFieldIsRequired'
    },
    timeout: {
        defaultMessage: 'Your session will expire soon due to inactivity.',
        id: 'warning.timeout'
    },
    timeoutLink: {
        defaultMessage: 'Logout',
        id: 'warning.timeout.link'
    },
    timer: {
        defaultMessage: 'Timer',
        id: 'generic.timer'
    },
    timerDurationCannotBeZero: {
        defaultMessage: 'Timer duration cannot be set to 0.',
        id: 'global.timerDurationCannotBeZero'
    },
    timerDurationMustBeLessThanFourHours: {
        defaultMessage: 'Timer duration must be less than 4 hours.',
        id: 'global.timerDurationMustBeLessThanFourHours'
    },
    timerDurationMustBeLessThanOneHour: {
        defaultMessage: 'Timer duration must be less than 1 hour.',
        id: 'global.timerDurationMustBeLessThanOneHour'
    },
    timerDurationMustBeLessThanTwoHours: {
        defaultMessage: 'Timer duration must be less than 2 hours.',
        id: 'global.timerDurationMustBeLessThanTwoHours'
    },
    timerDurationMustHaveFormatHoursMinutesSeconds: {
        defaultMessage: 'Timer duration must have format hh:mm:ss',
        id: 'global.timerDurationMustHaveFormatHoursMinutesSeconds'
    },
    timerDurationMustHaveFormatMinutesSeconds: {
        defaultMessage: 'Timer duration must have format mm:ss',
        id: 'global.timerDurationMustHaveFormatMinutesSeconds'
    },
    timezone: {
        defaultMessage: 'Timezone',
        id: 'timezone.label'
    },
    title: {
        defaultMessage: 'Title',
        id: 'generic.title'
    },
    today: {
        defaultMessage: 'today',
        id: 'time.today'
    },
    togglePTZ: {
        defaultMessage: 'Toggle PTZ Mode',
        id: 'sessions.viewer.togglePTZ'
    },
    tomorrow: {
        defaultMessage: 'tomorrow',
        id: 'time.tomorrow'
    },
    totalPoints: {
        defaultMessage: 'Total Points',
        id: 'questions.total_points'
    },
    trackbarLayoutOptions: {
        defaultMessage: 'Layout Options',
        id: 'trackbar.layout.options'
    },
    trim: {
        defaultMessage: 'Trim',
        id: 'trim'
    },
    trimSessionConfirmationBody: {
        defaultMessage: 'We will trim this in place. You will not have access to the original video.',
        id: 'sessions.viewer.confirmation_modal.body'
    },
    trimSessionConfirmationSubject: {
        defaultMessage: 'Trim Session',
        id: 'sessions.viewer.confirmation_modal.subject'
    },
    true: {
        defaultMessage: 'True',
        id: 'generic.true'
    },
    typeToSearch: {
        defaultMessage: 'Type to search',
        id: 'global.typeToSearch'
    },
    unableToAuthenticate: {
        defaultMessage: 'Unable to authenticate. Please try again.',
        id: 'password.error.unableToAuthenticate'
    },
    unassign: {
        defaultMessage: 'Unassign',
        id: 'generic.unassign'
    },
    unavailable: {
        defaultMessage: 'Unavailable',
        id: 'generic.unavailable'
    },
    unknownOrServerErrorBody: {
        defaultMessage: 'Something went wrong. Please try again later.',
        id: 'errors.unknown.body'
    },
    unknownPasswordError: {
        defaultMessage: 'Unknown password. Please try again or contact your administrator.',
        id: 'password.error.unknownPasswordError'
    },
    unselectAll: {
        defaultMessage: 'Unselect All',
        id: 'generic.unselectAll'
    },
    unsupportedFileTypeText: {
        defaultMessage: 'The media you have uploaded is not supported.',
        id: 'questions.add_media.unsupported_file_type.text'
    },
    unsupportedFileTypeTitle: {
        defaultMessage: 'Unsupported File Type',
        id: 'questions.add_media.unsupported_file_type.header'
    },
    untitledTab: {
        defaultMessage: 'Untitled Tab',
        id: 'emr_tabs.untitled_tab'
    },
    upcoming: {
        defaultMessage: 'Upcoming',
        id: 'schedule.upcoming'
    },
    upcomingDays: {
        defaultMessage: 'Upcoming {days} Days',
        id: 'schedule.upcoming_days'
    },
    upcomingEventsError: {
        defaultMessage: 'There was an error loading events.',
        id: 'schedule.upcoming.events_error'
    },
    upcomingNoEventsScheduled: {
        defaultMessage: 'There are no events scheduled in the next {days} days',
        id: 'schedule.upcoming.no_events'
    },
    upcomingTableHeadingDate: {
        defaultMessage: 'Date',
        id: 'schedule.upcoming.table_heading.date'
    },
    upcomingTableHeadingLocations: {
        defaultMessage: 'Locations',
        id: 'schedule.upcoming.table_heading.locations'
    },
    upcomingTableHeadingSimulator: {
        defaultMessage: 'Simulator',
        id: 'schedule.upcoming.table_heading.simulator'
    },
    upcomingTableHeadingTime: {
        defaultMessage: 'Time',
        id: 'schedule.upcoming.table_heading.time'
    },
    upcomingTableHeadingTitle: {
        defaultMessage: 'Title',
        id: 'schedule.upcoming.table_heading.title'
    },
    update: {
        defaultMessage: 'Update',
        id: 'generic.update'
    },
    updated: {
        defaultMessage: 'Updated',
        id: 'generic.updated'
    },
    uploadChooseFile: {
        defaultMessage: 'Choose File',
        id: 'upload.choose_file'
    },
    uploadDrag: {
        defaultMessage: 'Drag files here to upload them',
        id: 'upload.drag'
    },
    uploadDrop: {
        defaultMessage: 'Drop files to upload them',
        id: 'upload.drop'
    },
    user: {
        defaultMessage: 'User',
        id: 'generic.user'
    },
    userGroup: {
        defaultMessage: 'Role',
        id: 'generic.user_group'
    },
    userGroups: {
        defaultMessage: 'Roles',
        id: 'generic.user_groups'
    },
    userName: {
        defaultMessage: 'Username',
        id: 'generic.username'
    },
    userNumber: {
        defaultMessage: 'User Number',
        id: 'generic.user_number'
    },
    usernameErrorAlreadyExists: {
        defaultMessage: 'Username already exists.',
        id: 'generic.username.error.already_exists'
    },
    usernameErrorFormat: {
        defaultMessage:
            'Username must be between 3 and 64 characters long and contain no spaces, or be a valid email address.',
        id: 'generic.username.error.format'
    },
    usernameErrorPromptValid: {
        defaultMessage: 'Please enter a valid username.',
        id: 'generic.username.error.prompt_valid'
    },
    users: {
        defaultMessage: 'Users',
        id: 'generic.users'
    },
    usersGlobalNavigation: {
        defaultMessage: 'Users',
        description: 'Users label in global navigation',
        id: 'generic.users.global_navigation'
    },
    usmleDiagnosisAddNewRow: {
        defaultMessage: 'Add new row',
        id: 'questions.usmle_diagnosis.add_new_row'
    },
    usmleDiagnosisAnswerPlaceholder: {
        defaultMessage: 'Answer for this question will be filled out here.',
        id: 'questions.usmle_diagnosis.answer_placeholder'
    },
    usmleDiagnosisDiagnosisLabel: {
        defaultMessage: 'Diagnosis',
        description: 'To be used until we make USMLE question section labels editable.',
        id: 'questions.usmle_diagnosis.builder.diagnosis_label'
    },
    usmleDiagnosisHistoryLabel: {
        defaultMessage: 'History Finding(s)',
        description: 'To be used until we make USMLE question section labels editable.',
        id: 'questions.usmle_diagnosis.builder.history_label'
    },
    usmleDiagnosisPhysicalExamLabel: {
        defaultMessage: 'Physical Exam Finding(s)',
        description: 'To be used until we make USMLE question section labels editable.',
        id: 'questions.usmle_diagnosis.builder.physical_exam_label'
    },
    validationErrorInteger: {
        defaultMessage: 'Must be an integer.',
        id: 'validation.error.integer'
    },
    validationErrorNumber: {
        defaultMessage: 'Must be a valid number.',
        id: 'validation.error.number'
    },
    validationErrorPositiveNumber: {
        defaultMessage: 'Must be a positive number.',
        id: 'validation.error.positive_number'
    },
    validationErrorPositiveNumberOrZero: {
        defaultMessage: 'Must be a positive number or zero.',
        id: 'validation.error.positive_number_or_zero'
    },
    versionWarningModalContent: {
        defaultMessage: 'To ensure you are getting the latest features, please refresh your browser.',
        id: 'version_warning_modal.content'
    },
    versionWarningModalTitle: {
        defaultMessage: 'SimCapture Update Available',
        id: 'version_warning_modal.title'
    },
    videoEditingInstructions: {
        defaultMessage: 'Slide the indicators below to set the Start and Stop times',
        id: 'sessions.viewer.videoEditingInstructions'
    },
    videoEditingTitle: {
        defaultMessage: 'Video Editing in progress',
        id: 'sessions.viewer.videoEditingTitle'
    },
    videoIn: {
        defaultMessage: 'Camera',
        id: 'audioVideoSelector.videoIn'
    },
    viewer: {
        defaultMessage: 'Viewer',
        id: 'generic.viewer'
    },
    viewers: {
        defaultMessage: 'Viewers',
        id: 'generic.viewers'
    },
    volume: {
        defaultMessage: 'Volume',
        id: 'trackbar.volume'
    },
    waitingRoom: {
        defaultMessage: 'Waiting Room',
        id: 'waiting_room'
    },
    warning: {
        defaultMessage: 'Warning',
        id: 'warning'
    },
    week: {
        defaultMessage: 'Week',
        id: 'time.week'
    },
    weeks: {
        defaultMessage: 'Weeks',
        id: 'time.weeks'
    },
    year: {
        defaultMessage: 'Year',
        id: 'time.year'
    },
    years: {
        defaultMessage: 'Years',
        id: 'time.years'
    },
    yellow: {
        defaultMessage: 'Yellow',
        id: 'yellow'
    },
    yes: {
        defaultMessage: 'Yes',
        id: 'generic.yes'
    },
    yesterday: {
        defaultMessage: 'yesterday',
        id: 'time.yesterday'
    },
    youAreHere: {
        defaultMessage: 'You are here',
        id: 'youAreHere'
    }
};
