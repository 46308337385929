import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

import S from './sc-circular-progress.less';

const mediumSize = '2.5rem';
const smallSize = '2rem';
const thickness = 7;

const SCCircularProgress = ({size, ...circularProgressProps}) => {
    const sizeRem = size === 'small' ? smallSize : mediumSize;

    return (
        <div className={S.root}>
            <CircularProgress
                variant='determinate'
                className={S.bottom}
                size={sizeRem}
                thickness={thickness}
                role={null}
                {...circularProgressProps}
                value={100}
            />
            <CircularProgress
                variant='indeterminate'
                disableShrink={true}
                className={S.top}
                size={sizeRem}
                thickness={thickness}
                {...circularProgressProps}
            />
        </div>
    );
};

// SCLD-14893 conform to the medium size of 48px defined in Laerdal Life and migrate existing usages of the existing 25px size to either the 48px medium size or the 20px small size
SCCircularProgress.propTypes = {
    size: PropTypes.oneOf(['medium', 'small'])
};

SCCircularProgress.defaultProps = {
    size: 'medium'
};

export default SCCircularProgress;
