import isNil from 'lodash/isNil';

import {PASSWORD_REQUIREMENTS_BY_STRENGTH} from 'constants/password-schemas';
import globalMessages from 'intl/global-messages';

export const validateNewPassword = (passwordStrength, oldPass, newPass) => {
    const passwordRequirements = PASSWORD_REQUIREMENTS_BY_STRENGTH[passwordStrength - 1];
    const m = {
        errors: []
    };

    if (isNil(newPass) || !passwordRequirements.test(newPass)) {
        m.errors.push(getHelpTextMessage(passwordStrength));
    } else if (oldPass !== null && newPass === oldPass) {
        m.errors.push(globalMessages.oldMatch);
    }

    return m;
};

export const validateConfirmPassword = (newPassword, confirmPassword) => {
    const m = {
        errors: []
    };

    if (newPassword !== confirmPassword) {
        m.errors.push(globalMessages.noMatch);
    }

    return m;
};

export const getHelpTextMessage = (passwordStrength) => {
    if (passwordStrength === 1) {
        return globalMessages.passwordHelpWeak;
    } else if (passwordStrength === 2) {
        return globalMessages.passwordHelpMedium;
    } else if (passwordStrength === 3) {
        return globalMessages.passwordHelpStrong;
    } else {
        return null; // let them eat cake
    }
};
