import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';

import createFormManagerShape from './utils/create-form-manager-shape';
import {getDisplayName} from './utils/helpers';

import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import uniqueId from 'lodash/uniqueId';
import noop from 'lodash/noop';
import omit from 'lodash/omit';

import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/sample';
import 'rxjs/add/operator/distinctUntilChanged';

const {bool, number, func} = PropTypes;

/**
 * Manages the state of the form component and communicates with the underlying form controls.
 * It can be used as a decorator or composed on another component.
 *
 * `formManager` will pass down to the component it is wrapping :
 * (more description for each next to definition)
 * - `handleSubmit` {Function} Handles submit event or submitCallback function.
 * - `valid` {boolean} Reflects the validity of the form. Always current.
 * - `reset` {Function} Will reset all values to there defaultValue.
 *
 * The `handleSubmit` can take either an event or the `onSubmit` function. If the `formManager` does not have the
 * `onSubmit` function defined it cannot handle any submit and will error out.
 *
 * `onSubmit` can as well be passed down as an argument to the formManager instead of the `handleSubmit`.
 *
 * On submit the `formManager` will run the validators and if it was successful it will call `onSubmit`.
 *
 * The `formManager` exposes the following functions to the `formControl` :
 * (description for each function next to definition)
 *  - addControl
 *  - removeControl
 *  - isRegistered
 *  - onTouched
 *  - onChanged
 *  - updateValidity
 *  - triedSubmit
 *
 * -- Usage Example
 * Input field is component decorated with a formControl.
 * Parent Form Component :
 *
 * @formManager()
 * class FormComponent extends React.Component {
 *  ...
 *  render() {
 *      <div>
 *          <InputField />
 *          <button onClick={this.props.handleSubmit(this.save.bind(this))}>submit</button>
 *      </div>
 *  }
 * }
 * @deprecated Use Formik and material-ui components instead.
 * @param {Function} onSubmit The `onSubmit` function called once you submit the form and the validation is successful.
 * @param {object} options
 *        - {boolean} touchOnBlur marks fields to touched
 *          when the blur action is fired. Defaults to `true`.
 *        - {boolean} touchOnChange marks fields to touched
 *          when the change action is fired. Defaults to `false`.
 * @returns {Function}
 */
export default function formManager(
    onSubmit,
    options = {
        touchOnBlur: true,
        touchOnChange: false,
        subscribeDelay: 5000
    }
) {
    const id = uniqueId('FormManager');
    const formManagerShape = createFormManagerShape(PropTypes);

    return function wrapWithManager(WrappedComponentBase) {
        const WrappedComponent = React.forwardRef((props, ref) => <WrappedComponentBase {...props} ref={ref} />);
        class Manager extends React.Component {
            static displayName = `FormManager(${getDisplayName(WrappedComponent)})`;
            static WrappedComponent = WrappedComponent;

            shouldComponentUpdate(nextProps, nextState) {
                return nextProps !== this.props || this.state.valid !== nextState.valid;
            }

            static propTypes = {
                touchOnBlur: bool,
                touchOnChange: bool,
                subscribeDelay: number,
                onSubmit: func
            };

            static defaultProps = {
                touchOnBlur: options.touchOnBlur,
                touchOnChange: options.touchOnChange,
                subscribeDelay: options.subscribeDelay,
                onSubmit: onSubmit
            };

            static childContextTypes = {
                formManager: formManagerShape
            };

            getChildContext() {
                return {
                    formManager: this
                };
            }

            constructor(props, context) {
                super(props, context);

                this.controls = Immutable.OrderedMap();
                this.onUpdateSubscribe = noop;
                this.rx = new Subject();
                this.rxSubscriptions = Immutable.OrderedMap();
                this.state = {
                    valid: true,
                    triedSubmit: false
                };

                this.forceUpdateDebounced = this.forceUpdate;
                this.batchActions = Immutable.Map();
            }

            get touchOnBlur() {
                return this.props.touchOnBlur;
            }

            get touchOnChange() {
                return this.props.touchOnChange;
            }

            getWrappedInstance() {
                // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                return this.refs.wrappedInstance;
            }

            /**
             * Called by underlying `formControl` to add itself typically when the constructor is called
             * @param control
             */
            addControl(control) {
                this.controls = this.controls.set(control.uniqueName, control);
            }

            /**
             * Called by underlying `formControl` to remove itself typically on componentWillUnmount
             * @param control
             */
            removeControl(control) {
                this.controls = this.controls.delete(control.uniqueName);
            }

            /**
             * Checks if the control is registered or not.
             * @param control
             * @returns {*|boolean}
             */
            isRegistered(control) {
                return this.controls.has(control.uniqueName);
            }

            /**
             * Recursively creates a path to a formGroup
             * By going through all the parents to construct the tree.
             * @param {FormGroup} g
             * @param {Array.<string>} arr
             * @returns {Array.<string>}
             */
            keyPath(g, arr = []) {
                arr.push(g.uniqueName);
                let n = g.collection;
                if (isEmpty(n)) {
                    n = '$array$';
                }
                arr.push(n);
                if (g.parent) {
                    return this.keyPath(g.parent, arr);
                }
                return arr;
            }

            /**
             * Cleans object removing internal ids and converting Maps to Arrays if
             * they have no real objects in them
             * @param {Immutable.Collection<K, M>} v
             * @returns {Immutable.Collection<K, M>|M}
             */
            sanitize(v) {
                let valuesAreNil = true;
                v.forEach((value) => {
                    if (!isNil(value)) {
                        valuesAreNil = false;
                        return false;
                    }
                });
                if (valuesAreNil) {
                    return Immutable.List();
                }

                // eslint-disable-next-line no-shadow -- SCLD-17998
                v = v.map((v) => {
                    if (!Immutable.Map.isMap(v)) {
                        return v;
                    }
                    // eslint-disable-next-line no-shadow -- SCLD-17998
                    const a = v.findKey((v, k) => {
                        return k.indexOf('form-group') !== -1;
                    });

                    if (a) {
                        return this.sanitize(v.toList());
                    }

                    // Assumes if an object has no properties to be an empty list.
                    if (v.isEmpty()) {
                        return v.toList();
                    }

                    return this.sanitize(v);
                });

                if (v.has('$array$')) {
                    return v.get('$array$');
                }

                return v;
            }

            /**
             * Adds a new item. If it's nested in a group and the key doesn't exist,
             * we add the key and set the item. If it's nested in a group and the key
             * exists (or forceCollection is set), we assume it's an array and add
             * the item to it.
             */
            addNewItem(add, newItem) {
                let v = this.calculatedValues;

                // Adding a new Item
                if (add.group) {
                    const path = this.keyPath(add.group).reverse();
                    let listItem = v.getIn(path, Immutable.Map());
                    // assume it's an array or force it to be one
                    if (listItem.has(add.name) || add.forceCollection) {
                        let listItem2 = listItem.get(add.name, Immutable.Map());
                        listItem2 = listItem2.set('form-group-new', newItem);
                        listItem = listItem.set(add.name, listItem2);
                        // it's a new key, so add it
                    } else {
                        listItem = listItem.set(add.name, newItem);
                    }
                    v = v.setIn(path, listItem);
                } else {
                    let listItem = v.get(add.name, Immutable.Map());
                    listItem = listItem.set('form-group-new', newItem);
                    v = v.set(add.name, listItem);
                }

                v = this.sanitize(v);

                this.onUpdateSubscribe(v, this.valueValidityStatuses);
                this.forceUpdate();
            }

            updateItem(update, updateValue) {
                /*
                 NOTE: if we uncomment this code, there's a chance it will break the EvaluationTemplateBuilder
                 which is using this function!

                 let v = Immutable.Map({});

                 let updatePath = '';
                 if (update.group) {
                 updatePath = this.keyPath(update.group).reverse();
                 }

                 this.controls.forEach(value => {
                 if (value.group) {
                 let path = this.keyPath(value.group).reverse();
                 let listItem = v.getIn(path, Immutable.Map());
                 if (updatePath === path && value.name === update.name) {
                 listItem = listItem.set(value.name, updateValue);
                 } else {
                 listItem = listItem.set(value.name, value.value);
                 }
                 v = v.setIn(path, listItem);
                 } else {
                 v = v.set(value.name, value.value);
                 }
                 });

                 v = this.sanitize(v);
                 */

                this.onUpdateSubscribe(updateValue, this.valueValidityStatuses);
                this.forceUpdateDebounced();
            }

            removeItem(remove, postRemoveFn) {
                let v = this.calculatedValues;
                // Remove a new Item
                if (remove.group) {
                    const path = this.keyPath(remove.group).reverse();
                    if (remove.name) {
                        // Remove property
                        let list = v.getIn(path);
                        if (list.has(remove.name)) {
                            list = list.delete(remove.name);
                            if (list.size === 0) {
                                // if the group has no more properties delete the whole element.
                                v = v.deleteIn(path);
                            } else {
                                v = v.setIn(path, list);
                            }
                        }
                    } else {
                        //removes whole item.
                        v = v.deleteIn(path);
                    }
                } else if (v.has(remove.name)) {
                    v = v.delete(remove.name);
                }

                v = this.sanitize(v);

                if (!isUndefined(postRemoveFn)) {
                    v = postRemoveFn(v);
                }

                this.onUpdateSubscribe(v, this.valueValidityStatuses);
                this.forceUpdate();
            }

            get calculatedValues() {
                let v = Immutable.Map({});
                this.controls.forEach((value) => {
                    if (value.group) {
                        const path = this.keyPath(value.group).reverse();
                        let listItem = v.getIn(path, Immutable.Map());
                        listItem = listItem.set(value.name, value.value);
                        v = v.setIn(path, listItem);
                    } else {
                        v = v.set(value.name, value.value);
                    }
                });
                return v;
            }

            /**
             * Go through all the registered controls and returns the state of the form values.
             * where the properties key map to the name of the control or the uniqueId assigned to it.
             * ie: { field1: <value>, field2: <value> }
             * @returns {*|{}}
             */
            get values() {
                const v = this.calculatedValues;
                return this.sanitize(v);
            }

            get validValues() {
                let v = Immutable.Map({});
                this.controls.forEach((value) => {
                    if (!value.valid) {
                        return;
                    }
                    if (value.group) {
                        const path = this.keyPath(value.group).reverse();
                        let listItem = v.getIn(path, Immutable.Map());
                        listItem = listItem.set(value.name, value.value);
                        v = v.setIn(path, listItem);
                    } else {
                        v = v.set(value.name, value.value);
                    }
                });
                return this.sanitize(v);
            }

            get nextInvalidControl() {
                const invalidControl = this.controls.find((value) => {
                    return !value.valid;
                });
                return invalidControl;
            }

            findFormControl(predicate) {
                const formControl = this.controls.find(predicate);
                return formControl;
            }

            /**
             * Function for getting the validity of all the FormControls in the Form.
             * Mimics the structure of validValues but includes all values replaced with
             * the object { formValue: any, formValid: bool } to indicate the validity
             * of the control.
             *
             * Note: invalid means the component value is invalid AND the component has been
             * touched.
             */
            get valueValidityStatuses() {
                let v = Immutable.Map({});
                this.controls.forEach((value) => {
                    if (value.group) {
                        const path = this.keyPath(value.group).reverse();
                        let listItem = v.getIn(path, Immutable.Map());
                        listItem = listItem.set(
                            value.name,
                            Immutable.Map({
                                formValue: value.value,
                                formValid: value.valid || !value.touched
                            })
                        );
                        v = v.setIn(path, listItem);
                    } else {
                        v = v.set(
                            value.name,
                            Immutable.Map({
                                formValue: value.value,
                                formValid: value.valid || !value.touched
                            })
                        );
                    }
                });
                return this.sanitize(v);
            }

            /**
             * Called by underlying `formControl` when it is touched
             * We are waiting for all form controls to fire an onTouched event before we actually
             * update the value validity statuses and push it down on onUpdateSubscribe.
             * @param control {object}
             * @param actionId
             */
            onTouched(control, actionId) {
                if (!isNil(actionId) && this.batchActions.has(actionId)) {
                    this.batchActions = this.batchActions.set(actionId, this.batchActions.get(actionId) - 1);
                    if (this.batchActions.get(actionId) === 0) {
                        this.batchActions = this.batchActions.delete(actionId);
                        this.sanitize(this.values);
                        this.onUpdateSubscribe(this.values, this.valueValidityStatuses);
                    }
                }
            }

            /**
             * Called by underlying `formControl` when mounted
             */
            onInitialized() {
                this.updateValidity();
            }

            /**
             * Called by underlying `formControl` when it's state has changed.
             * @param control {object}
             * @param v {*}
             */
            onChanged(control, v) {
                this.rx.next(this.validValues);
                this.onUpdateSubscribe(this.values, this.valueValidityStatuses);
            }

            /**
             * Re-evaluate all registered controls for their validity and update the state accordingly
             */
            updateValidity() {
                if (this.allValid !== this.state.valid) {
                    this.setState({
                        valid: this.allValid
                    });
                }
            }

            get allValid() {
                return this.controls.every((v) => {
                    return v.valid;
                });
            }

            get isValidating() {
                return this.controls.reduce((r, v) => {
                    return r && v.isValidating;
                });
            }

            /**
             * Set to true in `handleSubmit`, defaults to false. Can be used if
             * a form control wants to render validation errors after the user
             * tries to submit an invalid form, even if the control is pristine
             * and untouched.
             */
            get triedSubmit() {
                return this.state.triedSubmit;
            }

            /**
             * An externally callable function that updates the Form to indicate
             * that a submit has been attempted, so that components will indicate
             * their validity.
             */
            triggerSubmitExternally() {
                this.markAllAsTouched();
                this.setState({
                    triedSubmit: true
                });
            }

            markAllAsTouched() {
                const actionId = uuidv4();
                this.batchActions = this.batchActions.set(actionId, this.controls.size);
                this.controls.forEach((c) => c.markAsTouched(actionId));
            }

            startSubmit() {}

            stopSubmit() {}

            /**
             * Will Reset all registered controls to there defaultValue.
             */
            reset() {
                this.controls.forEach((c) => c.reset());
            }

            subscribe(fn) {
                if (!this.rxSubscriptions.has(fn)) {
                    const rxSubscribe = this.rx
                        .sample(Observable.interval(this.props.subscribeDelay))
                        .distinctUntilChanged()
                        .subscribe(fn);

                    this.rxSubscriptions.set(fn, rxSubscribe);
                }
            }

            onUpdate(fn) {
                this.onUpdateSubscribe = fn;
            }

            componentWillUnmount() {
                this.rxSubscriptions.forEach((v) => {
                    v.unsubscribe();
                });

                this.rx.unsubscribe();
                this.rx = null;
                this.onUpdateSubscribe = noop;
            }

            render() {
                const _this = this;

                const handleSubmit = (submitOrEvent, onCompleteFn) => {
                    /**
                     * Will create and event handler which will either
                     * execute the `onSubmit` passed to the `formManager`
                     * Or it use the `onSubmit` function passed down to the
                     * `handleSubmit` function
                     * @param submit
                     * @param onComplete
                     */
                    const createEventHandler = (submit, onComplete) => (event) => {
                        if (event && event.preventDefault) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                        const submitWithPromiseCheck = () => {
                            const result = submit(_this.values, onComplete);
                            if (result) {
                                if (isFunction(result.then)) {
                                    const stopAndReturn = (x) => {
                                        _this.stopSubmit();
                                        return x;
                                    };
                                    _this.startSubmit();
                                    result.then(stopAndReturn, stopAndReturn);
                                } else {
                                    // not a promise
                                    return result;
                                }
                            }
                        };

                        _this.markAllAsTouched();
                        _this.setState({
                            triedSubmit: true
                        });

                        if (_this.allValid) {
                            /*                           if (asyncValidate) {
                             return runAsyncValidation().then(asyncValid => {
                             if (allValid && asyncValid) {
                             return submitWithPromiseCheck(values);
                             }
                             });
                             }
                             */
                            return submitWithPromiseCheck(_this.values);
                        }

                        if (_this.allValid && !_this.isValidating) {
                            // ignore for now
                        }
                    };

                    if (isFunction(submitOrEvent)) {
                        return createEventHandler(submitOrEvent, onCompleteFn);
                    }
                    // eslint-disable-next-line no-shadow -- SCLD-17998
                    const {onSubmit} = this.props;
                    if (isUndefined(onSubmit)) {
                        throw new Error(
                            `formManager "${this.constructor.displayName}::${id}" cannot submit,` +
                                `it needs an "onSubmit" function to be defined. ` +
                                `Either pass handleSubmit() an onSubmit function ` +
                                `or pass onSubmit as a prop to the component decorated with formManager` +
                                `or pass onSubmit as first argument of the formManager decorator.`
                        );
                    }
                    createEventHandler(onSubmit)(submitOrEvent /* is event */);
                };

                const sanitizedProps = omit(this.props, ['onSubmit', 'touchOnBlur', 'touchOnChange']);
                return (
                    <WrappedComponent
                        // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                        ref='wrappedInstance'
                        handleSubmit={handleSubmit}
                        onChangeSubscribe={this.subscribe.bind(this)}
                        onUpdateSubscribe={this.onUpdate.bind(this)}
                        updateItem={this.updateItem.bind(this)}
                        reset={this.reset.bind(this)}
                        valid={this.state.valid}
                        triedSubmit={this.state.triedSubmit}
                        {...sanitizedProps}
                    />
                );
            }
        }

        return Manager;
    };
}
