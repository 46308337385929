import Immutable from 'immutable';
import isNil from 'lodash/isNil';
import {getCourseTitle} from 'utils/course-utils';
import * as UserUtils from 'utils/user-utils';

export const ACTIVITY_LIMIT = 10; // the max number of activities to display in a list

// For each activity "type", returns information of how to display that type of
// activity in the UI. We use the redundant `modelName` key in the activity item
// to map to the "type" here. TODO: remove the pointless key and use the `verb`
// of the activity instead, which is more specific.
//
// Previously called IconMap for some reason.
export const ActivityTypeDisplayOptions = Immutable.fromJS([
    {
        type: 'login',
        icon: 'exit_to_app',
        color: '#E97116', // Warning 400
        targetObj: (value) => {
            const owner = value.get('owner');
            if (isNil(owner)) {
                return {title: '', subject: 'login'};
            } else {
                const title = UserUtils.getLastNameFirst(owner);
                return {title, subject: 'login'};
            }
        },
        buttonText: 'activityEditUser'
    },
    {
        type: 'course',
        icon: 'assignment',
        color: '#3FB9E8', // Primary 400
        targetObj: (activity) => {
            const subject = 'course';
            const course = activity.get(subject);
            const title = getCourseTitle(course, null);
            return {title, subject};
        },
        buttonText: 'activityEditCourse'
    },
    {
        type: 'user',
        icon: 'people',
        color: '#E97116', // Warning 400
        targetObj: (value) => {
            const subject = 'user';
            const title = UserUtils.getLastNameFirst(value.get(subject));
            return {title, subject};
        },
        buttonText: 'activityEditUser'
    },
    {
        type: 'reservation', // copied from user
        icon: 'people',
        color: '#E97116', // Warning 400
        targetObj: (value) => {
            const subject = 'reservation';
            const reservation = value.get(subject);
            const reservationTitle = reservation.get('reservationTitle');
            return {title: reservationTitle, subject};
        },
        buttonText: 'activityEditUser'
    },
    {
        type: 'scenario',
        icon: 'assignment',
        color: '#3FB9E8', // Primary 400
        targetObj: (value) => {
            const subject = 'scenario';
            const title = value.get(subject).get('privateTitle');
            return {title, subject};
        },
        buttonText: 'activityEditScenario'
    },
    {
        type: 'scenarioevaluationtemplate',
        icon: 'assignment',
        color: '#3FB9E8', // Primary 400
        targetObj: (value) => {
            const subject = 'evaluationTemplate';
            const title = value.get(subject).get('privateTitle');
            return {title, subject};
        },
        buttonText: 'activityEditEvaluationTemplate'
    },
    {
        type: 'scenarioreflectiontemplate',
        icon: 'assignment',
        color: '#3FB9E8', // Primary 400
        targetObj: (value) => {
            const subject = 'evaluationTemplate';
            const title = value.get(subject).get('privateTitle');
            return {title, subject};
        },
        buttonText: 'activityEditReflectionTemplate'
    },
    {
        type: 'session',
        icon: 'videocam',
        color: '#FC5A6F', // Critical 400
        targetObj: (value) => {
            const subject = 'session';
            const title = value.get(subject).get('title');
            return {title, subject};
        },
        buttonText: 'activityEditSession'
    }
]);
