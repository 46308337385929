import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import {ModalContext} from 'components/modals/modal-context';

const useStyles = makeStyles((theme) => {
    const paperCommon = {
        boxShadow: theme.shadows[1],
        borderRadius: '0.5rem'
    };

    return {
        paperSmall: {
            ...paperCommon,
            minWidth: '32rem',
            maxWidth: '47.9rem'
        },
        paperMedium: {
            ...paperCommon,
            minWidth: '48rem',
            maxWidth: '63.9rem'
        },
        paperLarge: {
            ...paperCommon,
            minWidth: '64rem',
            maxWidth: '72rem'
        }
    };
});

const SCModal = ({open, onClose, onExited, size, children}) => {
    const classes = useStyles();
    const theme = useTheme();
    const timeout = useMemo(
        () => ({
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen
        }),
        [theme.transitions.duration.enteringScreen, theme.transitions.duration.leavingScreen]
    );
    const modalContextValue = {
        size,
        open,
        onClose
    };

    let paper;
    switch (size) {
        case 'small':
            paper = classes.paperSmall;
            break;
        case 'large':
            paper = classes.paperLarge;
            break;
        case 'medium':
        default:
            paper = classes.paperMedium;
            break;
    }

    return (
        <Dialog
            classes={{paper}}
            open={open}
            onClose={onClose}
            TransitionProps={{
                onExited,
                timeout
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout
            }}>
            <ModalContext.Provider value={modalContextValue}>{children}</ModalContext.Provider>
        </Dialog>
    );
};

SCModal.propTypes = {
    children: PropTypes.any.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    onExited: PropTypes.func
};

SCModal.defaultProps = {
    size: 'medium',
    onClose: noop,
    onExited: noop
};

export default SCModal;
