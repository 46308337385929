/**
 * Weak: one or more characters of any type.
 *
 * Medium: 8 or more characters including at least one upper case, one lower
 * case, and one number.
 *
 * Strong: 10 or more characters including at least one upper case, one lower
 * case, one number, and one of the following special characters:
 * - `~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/
 */
export const PASSWORD_REQUIREMENTS_BY_STRENGTH = [
    /^.{1,}$/,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~\\!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]).{10,}/
] as const;
