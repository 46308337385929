import Immutable from 'immutable';
import * as ApiUtils from 'utils/api-utils';

/**
 * Returns the user's client. Used to populate the client in the session store
 * after login, so it cannot use the auth token from the store, and instead
 * passes the ids and token directly into the request utils.
 *
 * @param {string} clientId
 * @param {string} userId
 * @param {string} token
 * @returns {Promise}
 */
export const fetchClient = (clientId, userId, token) => {
    const headers = {
        'client-id': clientId,
        token,
        'user-id': userId
    };
    const request = ApiUtils.createRequest(headers, {
        endpoint: '/client',
        method: 'get'
    });

    return ApiUtils.executeApiRequest(request)
        .then((response) => {
            return Immutable.fromJS(response.data);
        })
        .catch(ApiUtils.handleGenericApiError);
};

/**
 * Returns the user's current client. Must be used
 * when the user is already authenticated.
 */
export const fetchClientAuthenticated = () => {
    return ApiUtils.callApiMeteorAuthenticatedUnsafe({
        endpoint: '/client',
        method: 'get'
    });
};
